import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Footer from "../components/partials/footer/Footer";
import Header from "../components/partials/header/Header";
import AsideCartMenu from "../components/utilitiesComponents/aside_cart_menu/AsideCartMenu";
import AsideSearchMenu from "../components/utilitiesComponents/aside_search_menu/AsideSearchMenu";
import QuickViewMenu from "../components/utilitiesComponents/quick_view_menu/QuickViewMenu";
import ScrollTop from "../components/utilitiesComponents/scroll_top/ScrollTop";
import SideMenu from "../components/utilitiesComponents/side_menu/SideMenu";
import { loadUser } from "../redux/actions/auth/authActions";

const Layout = () => {
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(loadUser());
  }, []);

  return (
    <div>
      <Header user={user} isAuthenticated={isAuthenticated} />
      <Outlet />
      <Footer />

      <QuickViewMenu />
      <AsideCartMenu />
      <AsideSearchMenu />
      <SideMenu user={user} isAuthenticated={isAuthenticated} />
      <ScrollTop />
    </div>
  );
};

export default Layout;
