import axios from "axios";
import {
  GET_ALL_BOOKS,
  FILTER_BOOKS_BY_CATEGORY,
  FILTER_BOOKS_BY_CATEGORY_ID,
  GET_BOOK_BY_ID,
  FILTER_BOOKS_BY_TAGS,
  MOST_VIEWED_BOOK,
  SEARCH_BOOKS,
  GET_POPULAR_BOOKS,
  GET_ERRORS,
  CLEAR_ERRORS,
  FAIL_BOOKS,
  GET_NEW_ARRIVALS,
  GET_ALL_BOOKS_REQUEST,
} from "./types";
import { linkUrl } from "../linkUrl";

export const getAllBooks = (sort, page) => async (dispatch) => {
  dispatch({ type: GET_ALL_BOOKS_REQUEST });
  axios
    .get(
      `${linkUrl}/books?${sort ? `_sort=${sort}` : ""}${
        page ? `_start=0&_limit=${page}` : ""
      }`
    )
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: GET_ALL_BOOKS,
          payload: result.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: FAIL_BOOKS,
        payload: err.response,
      });
    });
};

export const getSingleBook = (id) => async (dispatch) => {
  axios
    .get(`${linkUrl}/books/${id}`)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: GET_BOOK_BY_ID,
          payload: result.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: FAIL_BOOKS,
        payload: err.response,
      });
    });
};

export const filterBooksByCategory = (category) => async (dispatch) => {
  axios
    .get(`${linkUrl}/books?category=${category}`)
    .then((result) => {
      if (result.status === 200) {
        return dispatch({
          type: FILTER_BOOKS_BY_CATEGORY,
          payload: result.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: FAIL_BOOKS,
        payload: err.response,
      });
    });
};

// Search Books Function
export const searchBooks = (seValue) => async (dispatch) => {
  axios
    .get(`${linkUrl}/books?_q=${seValue}`)
    .then((result) => {
      if (result.status === 200) {
        return dispatch({
          type: SEARCH_BOOKS,
          payload: result.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: FAIL_BOOKS,
        payload: err.response,
      });
    });
};

// New Arrival Books
export const getNewArrivals = (trueValue) => async (dispatch) => {
  axios
    .get(`${linkUrl}/books?new_arrival=${trueValue}`)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: GET_NEW_ARRIVALS,
          payload: result.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: FAIL_BOOKS,
        payload: err,
      });
    });
};

// Get Popular Books
export const getPopularBooks = (trueValue) => async (dispatch) => {
  axios
    .get(`${linkUrl}/books?popular=${trueValue}`)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: GET_POPULAR_BOOKS,
          payload: result.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: FAIL_BOOKS,
        payload: err,
      });
    });
};

// export const filterBooksByTags = (tag) => async (dispatch) => {
//   axios
//     .get(`${linkUrl}/books?filters[tags][$eq]=${tag}`)
//     .then((result) => {
//       if (result.status === 200) {
//         dispatch({
//           type: FILTER_BOOKS_BY_TAGS,
//           payload: {
//             tag: tag,
//             payload: result.data,
//           },
//         });
//       }
//     })
//     .catch((err) => {
//     });
// };

export const getmostViewedBooks = (trueValue) => async (dispatch) => {
  axios
    .get(`${linkUrl}/books?most_viewed=${trueValue}`)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: MOST_VIEWED_BOOK,
          payload: result.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: FAIL_BOOKS,
        payload: err.response,
      });
    });
};

// Get Most Viewed Books

// clear Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
