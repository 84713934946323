import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../../constants/Constants";
import Catlv3 from "./Catlv3";

const SubChildCat = ({ id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [cat, setCat] = useState();

  useEffect(() => {
    getCategoryById(id);
  }, [dispatch]);

  const sideNav = (id) => {
    navigate(`/books?category=${id}`);
  };

  const getCategoryById = async (id) => {
    axios
      .get(`${BASE_URL}/categories/${id}`)
      .then((result) => {
        if (result.status === 200) {
          setCat(result.data);
        }
      })
      .catch((err) => {
        setCat(err);
      });
  };

  return (
    <ul className="dropdown-menu dropdown-submenu">
      {cat?.child?.map(
        (data, index) =>
          id == data.parent && (
            <li key={index}>
              <a
                className="dropdown-item fs-7"
                onClick={() => {
                  sideNav(data.id);
                }}
              >
                {data.has_child ? `${data.name} »` : data.name}
              </a>
              {data.has_child && <Catlv3 id={data.id} />}
            </li>
          )
      )}
    </ul>
  );
};

export default SubChildCat;
