import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../constants/Constants";
import { getSingleBook } from "../../../redux/actions/allBooks/allBooks";

import { getReviews } from "../../../redux/actions/reviews/reviewsActions";
import Loader from "../../loaderComponent/Loader";

const BookInfo = () => {
  const dispatch = useDispatch();

  const { reviews, loading } = useSelector((state) => state.reviews);
  const { isAuthenticated, user } = useSelector((state) => state.user);

  const [addReview, setAddReview] = useState(false);

  const { book } = useSelector((state) => state.allBooks);

  const initialValues = {
    comment: "",
    rating: 0,
    book: book.id,
    user: user.id,
  };

  const [postValues, setPostValues] = useState(initialValues);

  useEffect(() => {
    dispatch(getSingleBook(book.id));
    dispatch(getReviews(book.id));
  }, [dispatch]);

  const onSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${BASE_URL}/reviews`, postValues, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        toast.success("Review Added");
        dispatch(getReviews(book.id));
        setAddReview(false);
      })
      .catch((err) => toast.error("Something went wrong"));

    dispatch(getSingleBook(book.id));

    setPostValues(initialValues);
    e.target.reset();
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setPostValues({ ...postValues, [name]: value });
  };

  const ratingChanged = (e) => {
    setPostValues({ ...postValues, rating: e });
  };

  let reviewsData =
    reviews &&
    reviews.map((review, idx) => (
      <div className="review-item" key={idx}>
        <ul className="review-rating">
          <li>
            <ReactStars
              edit={false}
              color={"rgba(20, 20, 20, 0.1)"}
              activeColor={"#FFD167"}
              size={window.innerWidth < 600 ? 18 : 20}
              value={review.rating}
              isHalf={true}
            />
          </li>
        </ul>
        <h5 className="sub-title">
          <span>{review?.user?.username}</span> on{" "}
          <span>{String(review?.created_at).substring(0, 10)}</span>
        </h5>
        <p>{review.comment}</p>
      </div>
    ));

  if (book && book.reviews)
    return (
      <div className="row">
        <div className="col-12">
          <div className="product-review-tabs-content">
            <ul className="nav product-tab-nav" id="ReviewTab" role="tablist">
              <li role="presentation">
                <a
                  className="active"
                  id="information-tab"
                  data-bs-toggle="pill"
                  href="#information"
                  role="tab"
                  aria-controls="information"
                  aria-selected="true"
                >
                  Information
                </a>
              </li>
              <li role="presentation">
                <a
                  id="description-tab"
                  data-bs-toggle="pill"
                  href="#description"
                  role="tab"
                  aria-controls="description"
                  aria-selected="false"
                >
                  Description
                </a>
              </li>
              <li role="presentation">
                <a
                  id="reviews-tab"
                  data-bs-toggle="pill"
                  href="#reviews"
                  role="tab"
                  aria-controls="reviews"
                  aria-selected="false"
                >
                  Reviews{" "}
                  <span>
                    ({reviews && reviews.length ? reviews.length : 0})
                  </span>
                </a>
              </li>
            </ul>
            <div
              className="tab-content product-tab-content"
              id="ReviewTabContent"
            >
              <div
                className="tab-pane fade show active"
                id="information"
                role="tabpanel"
                aria-labelledby="information-tab"
              >
                <div className="product-information">
                  <div className="content">
                    <table className="table">
                      <tbody>
                        <tr>
                          <th scope="row">Title</th>
                          <td>{book.title}</td>
                        </tr>
                        <tr>
                          <th scope="row">Weight</th>
                          <td>{book.weight}</td>
                        </tr>
                        <tr>
                          <th scope="row">Total Pages</th>
                          <td>{book.pages}</td>
                        </tr>
                        <tr>
                          <th scope="row">Author</th>
                          <td>{book.author}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="description"
                role="tabpanel"
                aria-labelledby="description-tab"
              >
                <div className="product-description">
                  <p>{book.description}</p>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="reviews"
                role="tabpanel"
                aria-labelledby="reviews-tab"
              >
                <div className="product-review-content">
                  <div className="review-content-header">
                    <h3>Customer Reviews</h3>
                    <div className="review-info">
                      <div className="review-status">
                        {book.reviews && book.reviews.length > 0 ? (
                          <a href="#">({`${book.reviews.length}`} Reviews)</a>
                        ) : (
                          ""
                        )}
                      </div>
                      <ul className="review-rating">
                        <ReactStars
                          edit={false}
                          color={"rgba(20, 20, 20, 0.1)"}
                          activeColor={"#FFD167"}
                          size={window.innerWidth < 600 ? 20 : 25}
                          value={book?.average_rating}
                          isHalf={true}
                        />
                      </ul>

                      <button
                        style={{ border: "none" }}
                        className="review-write-btn"
                        disabled={!isAuthenticated}
                        onClick={() => setAddReview(!addReview)}
                      >
                        Write a review
                      </button>
                    </div>
                  </div>
                  {/* <!--== Start Reviews Form Item ==--> */}
                  {addReview ? (
                    <form onSubmit={onSubmit}>
                      <ReactStars
                        count={5}
                        onChange={ratingChanged}
                        size={24}
                        activeColor="#ffd700"
                      />

                      <div className="mb-3">
                        <label for="comment" className="form-label">
                          Comment
                        </label>
                        <textarea
                          className="form-control"
                          id="comment"
                          name="comment"
                          onChange={handleChange}
                          rows="3"
                        ></textarea>
                      </div>
                      <div className="col-auto">
                        <button type="submit" className="btn btn-primary mb-3">
                          Submit
                        </button>
                      </div>
                    </form>
                  ) : null}
                  {/* <!--== End Reviews Form Item ==--> */}
                  <div className="reviews-content-body">
                    {/* <!--== Start Reviews Content Item ==--> */}
                    {loading ? <Loader /> : reviewsData}
                    {/* <!--== End Reviews Content Item ==--> */}
                  </div>
                  {/* <!--== Start Reviews Pagination Item ==--> */}
                  <div className="review-pagination">
                    {/* <span className="pagination-pag">1</span>
                    <span className="pagination-pag">2</span>
                    <span className="pagination-next">Next »</span> */}
                  </div>
                  {/* <!--== End Reviews Pagination Item ==--> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default BookInfo;
