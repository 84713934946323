import React from "react";
import AllBooks from "./AllBooks";
import MostViewed from "./MostViewed";
import NewArrivals from "./NewArrivals";

const Books = () => {
  return (
    // <!--== Start Product Area Wrapper ==-->
    <section className="product-area product-default-area product-featured-area">
      <div className="container">
        <div className="row"></div>
        <div className="row bookNav">
          <ul
            className="nav nav-pills mb-3 justify-content-center"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-newArrivals-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-newArrivals"
                type="button"
                role="tab"
                aria-controls="pills-newArrivals"
                style={{ fontWeight: "bold" }}
                aria-selected="false"
              >
                New Arrivals
              </button>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-allBooks"
              role="tabpanel"
              aria-labelledby="pills-allBooks-tab"
            >
              <div className="row">
                <AllBooks />
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-newArrivals"
              role="tabpanel"
              aria-labelledby="pills-newArrivals-tab"
            >
              <div className="row">
                <NewArrivals />
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-contact"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
            >
              <div className="row">
                <MostViewed />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Books;
