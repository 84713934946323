import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { submitForm } from "../../../redux/actions/submitForm/submitForm";

const ContactForm = () => {
  const dispatch = useDispatch();
  const [contactInfo, setContactInfo] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    description: "",
  });

  const onChange = (e) => {
    e.preventDefault();
    setContactInfo({
      ...contactInfo,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(submitForm(contactInfo));

    setContactInfo({
      name: "",
      email: "",
      phone: "",
      address: "",
      description: "",
    });
  };

  return (
    <div className="contact-form-wrap">
      <div className="contact-form-title">
        <h2 className="title">Get in touch</h2>
      </div>

      <div className="contact-form">
        <form className="contact-form">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-control"
                  value={contactInfo.name}
                  onChange={(e) => onChange(e)}
                  placeholder="Full Name*"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                  value={contactInfo.email}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  className="form-control"
                  value={contactInfo.phone}
                  onChange={(e) => onChange(e)}
                  placeholder="Phone"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <input
                  type="text"
                  name="address"
                  id="address"
                  className="form-control"
                  value={contactInfo.address}
                  onChange={(e) => onChange(e)}
                  placeholder="Address"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <textarea
                  name="description"
                  id="description"
                  className="form-control"
                  value={contactInfo.description}
                  onChange={(e) => onChange(e)}
                  placeholder="Type your message here.."
                ></textarea>
              </div>
            </div>
          </div>
          <div className="contact-btn">
            <button type="submit" className="btn-theme" onClick={handleSubmit}>
              Send Email
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
