import React from "react";
import { Link, useNavigate } from "react-router-dom";
import SubChildCat from "./SubChildCat";

const SubCat = ({ category, id }) => {
  const navigate = useNavigate();

  const sideNav = (id) => {
    navigate(`/books?category=${id}`);
  };

  return (
    category &&
    category.child &&
    category.child.length > 0 &&
    category &&
    category.child &&
    category.child.map((categorylv1, idx) =>
      categorylv1.has_child ? (
        <>
          <li key={idx}>
            <a
              className="dropdown-item fs-7"
              onClick={() => {
                sideNav(categorylv1.id);
              }}
            >
              {categorylv1.name} &raquo;
            </a>
            <SubChildCat id={categorylv1.id} />
          </li>
        </>
      ) : (
        <>
          <li key={idx}>
            <a
              className="dropdown-item fs-7"
              onClick={() => {
                sideNav(categorylv1.id);
              }}
            >
              {categorylv1.name}
            </a>
          </li>
        </>
      )
    )
  );
};

export default SubCat;
