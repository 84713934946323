import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { getAllBooks } from "../../../../redux/actions/allBooks/allBooks";
import { Link, useNavigate } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import Image from "../../../Image/Image";

const AllBooks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, filterBooks, imgUrl } = useSelector(
    (state) => state.allBooks
  );

  useEffect(() => {
    dispatch(getAllBooks("", 10));
  }, [dispatch]);

  const redirect = (bookId) => {
    navigate(`/product/${bookId}`);
  };

  let allbooks;
  if (loading) {
    return "";
  } else {
    allbooks =
      filterBooks &&
      filterBooks.map((book, idx) => (
        <div className="slide-product justify-content-center" key={idx}>
          <div className="product-item">
            <div className="inner-content">
              <div className="product-thumb">
                <a href={`/book/${book.id}`}>
                  <Image
                    imageUrl={book?.thumbnail?.formats?.small?.url}
                    alt="Image-HasTech"
                  />
                </a>

                <div className="product-action">
                  <a className="btn-product-wishlist" href="shop-wishlist.html">
                    <i className="fa fa-heart"></i>
                  </a>
                  <a className="btn-product-cart" href="shop-cart.html">
                    <i className="fa fa-shopping-cart"></i>
                  </a>
                </div>
                <a className="banner-link-overlay" href="shop.html"></a>
              </div>
              <div className="product-info">
                <div className="category">
                  <ul>
                    <li>
                      <ReactStars
                        edit={false}
                        color={"rgba(20, 20, 20, 0.1)"}
                        activeColor={"#FFD167"}
                        size={window.innerWidth < 600 ? 18 : 20}
                        value={book.average_rating}
                        isHalf={true}
                      />
                    </li>
                  </ul>
                </div>
                <h4 className="title">
                  <a href="single-product.html">{book.title}</a>
                </h4>
                <div className="prices">
                  <span className="price">NRs.{book.price}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
  }

  // Carousel Settings
  // let i = !filterBooks || filterBooks.length <= 5 ? false : true;
  // let r = !filterBooks || filterBooks.length <= 10 ? 1 : 2;
  const settings = {
    infinite: true,
    slidesToShow: 5,
    speed: 3000,
    autoplaySpeed: 3000,
    autoplay: true,
    rows: 2,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          rows: 1,
          slidesPerRow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          rows: 1,
          slidesPerRow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          rows: 1,
          slidesPerRow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          className: "center",
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
          slidesPerRow: 1,
        },
      },
    ],
  };

  return <Slider {...settings}>{allbooks}</Slider>;
};

export default AllBooks;
