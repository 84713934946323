import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { loadUser, logout } from "../../../redux/actions/auth/authActions";
import { getTeacherInfo } from "../../../redux/actions/teacher/teacherActions";
import Loader from "../../loaderComponent/Loader";

const DashSidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, loading, isAuthenticated } = useSelector((state) => state.user);
  const { teacher } = useSelector((state) => state.teacher);

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      dispatch(getTeacherInfo(user.id));
    }
  }, [dispatch, user]);

  // useEffect(() => {
  //   if (isAuthenticated === false) {
  //     navigate("/sign-in");
  //   }
  // }, [navigate]);

  const logOut = () => {
    dispatch(logout());
    navigate("/");
  };

  let AuthLinks =
    teacher && teacher !== undefined ? (
      <>
        <button
          className="nav-link"
          id="teacher-books-tab"
          data-bs-toggle="tab"
          data-bs-target="#teacher-books"
          type="button"
          role="tab"
          aria-controls="teacher-books"
          aria-selected="false"
        >
          Teacher's Books
        </button>

        <button
          className="nav-link"
          id="academic-info-tab"
          data-bs-toggle="tab"
          data-bs-target="#academic-info"
          type="button"
          role="tab"
          aria-controls="academic-info"
          aria-selected="false"
        >
          Academic Info
        </button>
      </>
    ) : (
      ""
    );

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <nav>
          <div
            className="myaccount-tab-menu nav nav-tabs"
            id="nav-tab"
            role="tablist"
          >
            <button
              className="nav-link active"
              id="dashboad-tab"
              data-bs-toggle="tab"
              data-bs-target="#dashboad"
              type="button"
              role="tab"
              aria-controls="dashboad"
              aria-selected="true"
            >
              Dashboard
            </button>
            <button
              className="nav-link"
              id="orders-tab"
              data-bs-toggle="tab"
              data-bs-target="#orders"
              type="button"
              role="tab"
              aria-controls="orders"
              aria-selected="false"
            >
              Orders
            </button>
            <button
              className="nav-link"
              id="download-tab"
              data-bs-toggle="tab"
              data-bs-target="#download"
              type="button"
              role="tab"
              aria-controls="download"
              aria-selected="false"
            >
              Library
            </button>
            {AuthLinks}
            {/* <button
            className="nav-link"
            id="payment-method-tab"
            data-bs-toggle="tab"
            data-bs-target="#payment-method"
            type="button"
            role="tab"
            aria-controls="payment-method"
            aria-selected="false"
          >
            Payment Method
          </button> */}
            <button
              className="nav-link"
              id="address-edit-tab"
              data-bs-toggle="tab"
              data-bs-target="#address-edit"
              type="button"
              role="tab"
              aria-controls="address-edit"
              aria-selected="false"
            >
              address
            </button>
            <button
              className="nav-link"
              id="account-info-tab"
              data-bs-toggle="tab"
              data-bs-target="#account-info"
              type="button"
              role="tab"
              aria-controls="account-info"
              aria-selected="false"
            >
              Account Details
            </button>
            <button
              className="nav-link"
              id="password-change-tab"
              data-bs-toggle="tab"
              data-bs-target="#password-change"
              type="button"
              role="tab"
              aria-controls="password-change"
              aria-selected="false"
            >
              Change Password
            </button>
            <button
              className="nav-link"
              // onclick="window.location.href='account-login.html'"
              type="submit"
              onClick={logOut}
            >
              Logout
            </button>
          </div>
        </nav>
      )}
    </>
  );
};

export default DashSidebar;
