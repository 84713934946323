import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { loadUser } from "../../../redux/actions/auth/authActions";
import {
  getItemsFromCart,
  removeFromCart,
  getCartTotal,
  clearcart,
} from "../../../redux/actions/cart/cartAction";

const AsideCartMenu = () => {
  const dispatch = useDispatch();

  const { user, isAuthenticated } = useSelector((state) => state.user);
  const { arrCartItems, imgUrl, loading, totalAmount } = useSelector(
    (state) => state.cart
  );

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated === true) {
      dispatch(getItemsFromCart(user.id));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCartTotal(arrCartItems));
  }, [dispatch, arrCartItems]);

  const removeItem = (e, cartItemId) => {
    e.preventDefault();
    if (user) {
      dispatch(removeFromCart(cartItemId, user.id));
    }
  };

  let cartItems;
  let itemCount;

  if (arrCartItems && arrCartItems.cart_items !== undefined) {
    cartItems = arrCartItems.cart_items.map((cart_item, idx) =>
      cart_item.book === null ? (
        ""
      ) : (
        <li className="product-list-item" key={idx}>
          <a
            className="text-danger remove"
            style={{ cursor: "pointer" }}
            type="button"
            onClick={(e) => removeItem(e, cart_item.id)}
          >
            ×
          </a>
          <a href="#">
            {cart_item &&
            cart_item.book &&
            cart_item.book.thumbnail &&
            cart_item.book.thumbnail.formats &&
            cart_item.book.thumbnail.formats.thumbnail &&
            cart_item.book.thumbnail.formats.thumbnail.url ? (
              <img
                src={
                  cart_item.book && cart_item.book.thumbnail
                    ? `${imgUrl}${cart_item.book.thumbnail.formats.thumbnail.url}`
                    : "/images/logo/readmore-logo.png"
                }
                width="90"
                height="110"
                alt="Image-HasTech"
              />
            ) : (
              ""
            )}

            <span className="product-title">{cart_item.book.title}</span>
          </a>
          <span className="product-price">
            {cart_item.item} * {cart_item.book.price}
          </span>
        </li>
      )
    );
  }

  return (
    <div
      className="aside-cart-wrapper offcanvas offcanvas-end"
      tabIndex="-1"
      id="AsideOffcanvasCart"
      aria-labelledby="offcanvasRightLabel"
    >
      <div className="offcanvas-header">
        <h1 id="offcanvasRightLabel"></h1>
        <button
          className="btn-aside-cart-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          Shopping Cart <i className="fa fa-chevron-right"></i>
        </button>
      </div>
      <div className="offcanvas-body">
        <ul className="aside-cart-product-list">{cartItems}</ul>

        <p className="cart-total">
          <span>Subtotal:</span>
          <span className="amount">NRs.{totalAmount}</span>
        </p>
        <a className="btn-theme" style={{ marginBottom: "10px" }} href="/cart">
          View cart
        </a>
        <a className="btn-theme" href="/check-out">
          Checkout
        </a>
      </div>
    </div>
  );
};

export default AsideCartMenu;
