import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const DataDelete = () => {
  const history = useNavigate();

  const [data, setData] = useState({
    username: "",
    email: "",
    phoneNumber: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    toast.info("Data will be deleted within 15 days.");

    setData({
      username: "",
      email: "",
      phoneNumber: "",
    });
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.id]: e.target.value,
    });
  };

  return (
    <main className="main-content">
      <ToastContainer autoClose={4000} />
      <section className="account-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="login-form-content">
                <form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="username">
                          Username
                          <span className="required">*</span>
                        </label>
                        <input
                          id="username"
                          name="username"
                          className="form-control"
                          type="text"
                          value={data.username}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="email">
                          Email address
                          <span className="required">*</span>
                        </label>
                        <input
                          id="email"
                          name="email"
                          type="email"
                          className="form-control"
                          value={data.email}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="phoneNumber">
                          Phone Number <span className="required">*</span>
                        </label>
                        <input
                          id="phoneNumber"
                          name="phoneNumber"
                          className="form-control"
                          inputMode="numeric"
                          value={data.phoneNumber}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <button type="submit" className="btn-login">
                          Delete Data
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default DataDelete;
