import React from "react";

const ScrollTop = () => {
  return (
    <></>
    //   <!--== Scroll Top Button ==-->
    // <div id="scroll-to-top" className="scroll-to-top">
    //   <span className="fa fa-angle-up"></span>
    // </div>
  );
};

export default ScrollTop;
