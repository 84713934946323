import React, { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../loaderComponent/Loader";
import {
  filterBooksByCategory,
  getNewArrivals,
  getmostViewedBooks,
  getPopularBooks,
  searchBooks,
  getAllBooks,
} from "../../../../redux/actions/allBooks/allBooks";
import { useQuery } from "../../../queryHelper/QueryHelper";
import Image from "../../../Image/Image";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { linkUrl } from "../../../../redux/actions/linkUrl";
import { ClipLoader, BounceLoader } from "react-spinners";
import BarLoader from "react-spinners/BarLoader";

const BooksList = () => {
  const dispatch = useDispatch();
  let query = useQuery();
  let categoryQuery = query.get("category");
  let newArrivalQuery = query.get("new_arrivals");
  let mostViewedQuery = query.get("most_viewed");
  let popularBookQuery = query.get("popular_books");
  let searchBookQuery = query.get("search_books");

  const { filterBooks, filterBooksByCat, imgUrl, loading } = useSelector(
    (state) => state.allBooks
  );

  const [page, setPage] = useState(10);
  const [total, setTotal] = useState(100);

  useEffect(() => {
    dispatch(getAllBooks("", page));

    axios.get(`${linkUrl}/books/count`).then((res) => setTotal(res.data));
  }, [dispatch]);

  useEffect(() => {
    if (categoryQuery) {
      dispatch(filterBooksByCategory(categoryQuery));
    }

    if (newArrivalQuery) {
      dispatch(getNewArrivals(newArrivalQuery));
    }

    if (mostViewedQuery) {
      dispatch(getmostViewedBooks(mostViewedQuery));
    }

    if (popularBookQuery) {
      dispatch(getPopularBooks(popularBookQuery));
    }
    if (searchBookQuery) {
      dispatch(searchBooks(searchBookQuery));
    }
  }, [
    dispatch,
    categoryQuery,
    newArrivalQuery,
    mostViewedQuery,
    popularBookQuery,
    searchBookQuery,
  ]);

  const fetchMoreData = async () => {
    setPage((prev) => prev + 10);

    setTimeout(async () => {
      dispatch(getAllBooks("", page + 10));
    }, 500);
  };

  let bookGrid;
  if (categoryQuery) {
    bookGrid =
      filterBooksByCat &&
      filterBooksByCat.map((book, idx) => (
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3" key={idx}>
          <div className="product-item d-flex justify-content-center">
            <div className="inner-content">
              <div className="product-thumb">
                <a href={`/book/${book.id}`}>
                  <Image
                    imageUrl={book?.thumbnail?.formats?.small?.url}
                    alt="product image"
                    style={" width=100%, height=245px"}
                  />
                </a>

                <div className="product-action">
                  <a className="btn-product-wishlist" href="shop-wishlist.html">
                    <i className="fa fa-heart"></i>
                  </a>
                  <a className="btn-product-cart" href="">
                    <i className="fa fa-shopping-cart"></i>
                  </a>
                </div>
                <a className="banner-link-overlay" href=""></a>
              </div>
              <div className="product-info">
                <div className="category">
                  <ul>
                    <ReactStars
                      edit={false}
                      color={"rgba(20, 20, 20, 0.1)"}
                      activeColor={"#FFD167"}
                      size={window.innerWidth < 600 ? 18 : 20}
                      value={book.average_rating}
                      isHalf={true}
                    />
                  </ul>
                </div>
                <h4 className="title">
                  <a href={`/book/${book.id}`}>{book.title}</a>
                </h4>
                <div className="prices">
                  <span className="price">NRs.{book.price}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
  } else if (newArrivalQuery) {
    bookGrid =
      filterBooksByCat &&
      filterBooksByCat.map((book, idx) => (
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3" key={idx}>
          <div className="product-item d-flex justify-content-center">
            <div className="inner-content">
              <div className="product-thumb">
                <a href={`/book/${book.id}`}>
                  <Image
                    imageUrl={book?.thumbnail?.formats?.small?.url}
                    alt="product image"
                    style={" width=100%, height=245px"}
                  />
                </a>
                <div className="product-action">
                  <a className="btn-product-wishlist" href="shop-wishlist.html">
                    <i className="fa fa-heart"></i>
                  </a>
                  <a className="btn-product-cart" href="">
                    <i className="fa fa-shopping-cart"></i>
                  </a>
                </div>
                <a className="banner-link-overlay" href=""></a>
              </div>
              <div className="product-info">
                <div className="category">
                  <ul>
                    <ReactStars
                      edit={false}
                      color={"rgba(20, 20, 20, 0.1)"}
                      activeColor={"#FFD167"}
                      size={window.innerWidth < 600 ? 18 : 20}
                      value={book.average_rating}
                      isHalf={true}
                    />
                  </ul>
                </div>
                <h4 className="title">
                  <a href={`/book/${book.id}`}>{book.title}</a>
                </h4>
                <div className="prices">
                  <span className="price">NRs.{book.price}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
  } else if (mostViewedQuery) {
    bookGrid =
      filterBooksByCat &&
      filterBooksByCat.map((book, idx) => (
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3" key={idx}>
          <div className="product-item d-flex justify-content-center">
            <div className="inner-content">
              <div className="product-thumb">
                <a href={`/book/${book.id}`}>
                  <Image
                    imageUrl={book?.thumbnail?.formats?.small?.url}
                    alt="product image"
                    style={" width=100%, height=245px"}
                  />
                </a>

                <div className="product-action">
                  <a className="btn-product-wishlist" href="shop-wishlist.html">
                    <i className="fa fa-heart"></i>
                  </a>
                  <a className="btn-product-cart" href="">
                    <i className="fa fa-shopping-cart"></i>
                  </a>
                </div>
                <a className="banner-link-overlay" href=""></a>
              </div>
              <div className="product-info">
                <div className="category">
                  <ul>
                    <ReactStars
                      edit={false}
                      color={"rgba(20, 20, 20, 0.1)"}
                      activeColor={"#FFD167"}
                      size={window.innerWidth < 600 ? 18 : 20}
                      value={book.average_rating}
                      isHalf={true}
                    />
                  </ul>
                </div>
                <h4 className="title">
                  <a href={`/book/${book.id}`}>{book.title}</a>
                </h4>
                <div className="prices">
                  <span className="price">NRs.{book.price}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
  } else if (popularBookQuery) {
    bookGrid =
      filterBooksByCat &&
      filterBooksByCat.map((book, idx) => (
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3" key={idx}>
          <div className="product-item d-flex justify-content-center">
            <div className="inner-content">
              <div className="product-thumb">
                <a href={`/book/${book.id}`}>
                  <Image
                    imageUrl={book?.thumbnail?.formats?.small?.url}
                    alt="product image"
                    style={" width=100%, height=245px"}
                  />
                </a>

                <div className="product-action">
                  <a className="btn-product-wishlist" href="shop-wishlist.html">
                    <i className="fa fa-heart"></i>
                  </a>
                  <a className="btn-product-cart" href="">
                    <i className="fa fa-shopping-cart"></i>
                  </a>
                </div>
                <a className="banner-link-overlay" href=""></a>
              </div>
              <div className="product-info">
                <div className="category">
                  <ul>
                    <ReactStars
                      edit={false}
                      color={"rgba(20, 20, 20, 0.1)"}
                      activeColor={"#FFD167"}
                      size={window.innerWidth < 600 ? 18 : 20}
                      value={book.average_rating}
                      isHalf={true}
                    />
                  </ul>
                </div>
                <h4 className="title">
                  <a href={`/book/${book.id}`}>{book.title}</a>
                </h4>
                <div className="prices">
                  <span className="price">NRs.{book.price}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
  } else if (searchBookQuery) {
    bookGrid =
      filterBooksByCat &&
      filterBooksByCat.map((book, idx) => (
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3" key={idx}>
          <div className="product-item d-flex justify-content-center">
            <div className="inner-content">
              <div className="product-thumb">
                <a href={`/book/${book.id}`}>
                  <Image
                    imageUrl={book?.thumbnail?.formats?.small?.url}
                    alt="product image"
                    style={" width=100%, height=245px"}
                  />
                </a>
                <div className="product-action">
                  <a className="btn-product-wishlist" href="shop-wishlist.html">
                    <i className="fa fa-heart"></i>
                  </a>
                  <a className="btn-product-cart" href="">
                    <i className="fa fa-shopping-cart"></i>
                  </a>
                </div>
                <a className="banner-link-overlay" href=""></a>
              </div>
              <div className="product-info">
                <div className="category">
                  <ul>
                    <ReactStars
                      edit={false}
                      color={"rgba(20, 20, 20, 0.1)"}
                      activeColor={"#FFD167"}
                      size={window.innerWidth < 600 ? 18 : 20}
                      value={book.average_rating}
                      isHalf={true}
                    />
                  </ul>
                </div>
                <h4 className="title">
                  <a href={`/book/${book.id}`}>{book.title}</a>
                </h4>
                <div className="prices">
                  <span className="price">NRs.{book.price}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
  } else {
    bookGrid = (
      <InfiniteScroll
        dataLength={filterBooks?.length}
        next={fetchMoreData}
        hasMore={filterBooks?.length < total}
        loader={<BarLoader color="#0A4268" />}
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, 200px)",
          gap: "1em",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b></b>
          </p>
        }
      >
        {filterBooks &&
          filterBooks.map((book, idx) => (
            <div key={idx}>
              <div className="product-item d-flex justify-content-center">
                <div className="inner-content">
                  <div className="product-thumb">
                    <a href={`/book/${book.id}`}>
                      <Image
                        imageUrl={book?.thumbnail?.formats?.small?.url}
                        alt="product image"
                        style={" width=100%, height=245px"}
                      />
                    </a>
                    <div className="product-action">
                      <a
                        className="btn-product-wishlist"
                        href="shop-wishlist.html"
                      >
                        <i className="fa fa-heart"></i>
                      </a>
                      <a className="btn-product-cart" href="">
                        <i className="fa fa-shopping-cart"></i>
                      </a>
                    </div>
                    <a className="banner-link-overlay" href=""></a>
                  </div>
                  <div className="product-info">
                    <div className="category">
                      <ul>
                        <ReactStars
                          edit={false}
                          color={"rgba(20, 20, 20, 0.1)"}
                          activeColor={"#FFD167"}
                          size={window.innerWidth < 600 ? 18 : 20}
                          value={book.average_rating}
                          isHalf={true}
                        />
                      </ul>
                    </div>
                    <h4 className="title">
                      <a href={`/book/${book.id}`}>{book.title}</a>
                    </h4>
                    <div className="prices">
                      <span className="price">NRs.{book.price}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </InfiniteScroll>
    );
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="tab__container">
          {!bookGrid || bookGrid.length <= 0 ? (
            "Currently No Books Available"
          ) : (
            <div
              className="shop-grid tab-pane fade show active"
              id="nav-grid"
              role="tabpanel"
            >
              <div className="col-12">
                <div className="row">{bookGrid}</div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default BooksList;
