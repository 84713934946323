import React, { useEffect } from "react";
import { getItemsFromCart } from "../../../redux/actions/cart/cartAction";

import { useDispatch, useSelector } from "react-redux";

const HeaderCart = ({ user, isAuthenticated }) => {
  const dispatch = useDispatch();

  const { arrCartItems } = useSelector((state) => state.cart);
  useEffect(() => {
    if (user && user.id) {
      dispatch(getItemsFromCart(user.id));
    }
  }, [dispatch, user]);

  let itemCount =
    arrCartItems && arrCartItems.cart_items ? (
      <sup className="shop-count">{arrCartItems.cart_items.length}</sup>
    ) : (
      ""
    );

  // let favBooks =
  //   isAuthenticated === true ? (
  //     <a className="shopping-wishlist-btn" href="#">
  //       <i className="pe-7s-like icon"></i>
  //     </a>
  //   ) : (
  //     ""
  //   );

  return (
    <div className="header-middle-align-end">
      <div className="header-action-area">
        <div className="shopping-search">
          <button
            className="shopping-search-btn"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#AsideOffcanvasSearch"
            aria-controls="AsideOffcanvasSearch"
          >
            <i className="pe-7s-search icon"></i>
          </button>
        </div>
        {/* <div className="shopping-wishlist">{favBooks}</div> */}
        <div className="shopping-cart">
          <button
            className="shopping-cart-btn"
            title="Cart"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#AsideOffcanvasCart"
            aria-controls="offcanvasRightLabel"
          >
            <i className="pe-7s-shopbag icon"></i>
            {itemCount}
          </button>
        </div>
        <button
          className="btn-menu"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#AsideOffcanvasMenu"
          aria-controls="AsideOffcanvasMenu"
        >
          <i className="pe-7s-menu"></i>
        </button>
      </div>
    </div>
  );
};

export default HeaderCart;
