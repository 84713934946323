import React from "react";
import BooksList from "./booksList/BooksList";
import MainCat from "./sidebar/MainCat";

const Books = () => {
  return (
    <main className="main-content">
      <div
        className="page-header-area parallax"
        style={{
          backgroundImage: `url(/assets/images/banner.jpg)`,
          backgroundBlendMode: "multiply",
          backgroundColor: "grey",
        }}
      >
        <div className="container pt--0 pb--0">
          <div className="row">
            <div className="col-12">
              <div className="page-header-content">
                <h2
                  className="title text-danger"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <span
                    style={{ color: "#fff", textShadow: "1px 1px 2px red" }}
                  >
                    Categories & Books
                  </span>
                </h2>
                <nav
                  className="breadcrumb-area"
                  data-aos="fade-down"
                  data-aos-duration="1200"
                >
                  <ul
                    className="breadcrumb"
                    style={{
                      color: "#fff",
                      textShadow: "1px 1px 2px black",
                    }}
                  >
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-sep">/</li>
                    <li>Books Page</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Products Area */}
      <section className="product-area product-default-area">
        <div className="container">
          <div className="row flex-xl-row-reverse justify-content-between">
            <div className="col-xl-9">
              <div className="row">
                <BooksList />
              </div>
            </div>

            {/* SideBar and Filter Product */}
            <div className="col-xl-3">
              <div className="shop-sidebar">
                <div className="shop-sidebar-category">
                  <h4 className="sidebar-title">Books Categories</h4>
                  <div className="sidebar-category">
                    <MainCat />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Books;
