import React from "react";
import logo from "../../assets/logo.png";
import { BASE_URL } from "../../constants/Constants";

const Image = ({ imageUrl, alt, style, className }) => {
  console.log();
  return (
    <img
      src={imageUrl === undefined ? logo : `${BASE_URL}${imageUrl}`}
      alt={alt}
      className={className}
      style={{ style }}
    />
  );
};

export default Image;
