import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Loader from "../loaderComponent/Loader";

import { login } from "../../redux/actions/auth/authActions";

const LoginForm = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/my-dashboard";

  const [loginuser, setLoginuser] = useState({
    identifier: "",
    password: "",
  });

  const { loading, isAuthenticated, user } = useSelector((state) => state.user);

  useEffect(() => {
    if (isAuthenticated === true) {
      navigate(from, { replace: true });
    }
  }, [isAuthenticated, navigate]);

  const handleChange = (e) => {
    setLoginuser({
      ...loginuser,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(loginuser));

    setLoginuser({
      identifier: "",
      password: "",
    });
  };
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <main className="main-content">
          <div
            className="page-header-area parallax"
            style={{
              backgroundImage: `url(/assets/images/banner.jpg)`,
              backgroundBlendMode: "multiply",
              backgroundColor: "grey",
            }}
          >
            <div className="container pt--0 pb--0">
              <div className="row">
                <div className="col-12">
                  <div className="page-header-content">
                    <h2
                      className="title"
                      data-aos="fade-down"
                      data-aos-duration="1000"
                      style={{ color: "#fff", textShadow: "1px 1px 2px red" }}
                    >
                      Login
                    </h2>
                    <nav
                      className="breadcrumb-area"
                      data-aos="fade-down"
                      data-aos-duration="1200"
                    >
                      <ul
                        className="breadcrumb"
                        style={{
                          color: "#fff",
                          textShadow: "1px 1px 2px black",
                        }}
                      >
                        <li>
                          <a href="index.html">Home</a>
                        </li>
                        <li className="breadcrumb-sep">/</li>
                        <li>Login</li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="account-area">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="login-form-content">
                    <form onSubmit={(e) => handleSubmit(e)}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="username">
                              email address
                              <span className="required">*</span>
                            </label>
                            <input
                              id="identifier"
                              name="email"
                              className="form-control"
                              type="email"
                              value={loginuser.identifier}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="password">
                              Password <span className="required">*</span>
                            </label>
                            <input
                              id="password"
                              name="password"
                              className="form-control"
                              type="password"
                              value={loginuser.password}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <button type="submit" className="btn-login">
                              Login
                            </button>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group account-info-group mb--0">
                            <div className="rememberme-account">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="defaultCheck1"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck1"
                                >
                                  Remember me
                                </label>
                              </div>
                            </div>
                            <Link to={"/forgot-password"}>
                              Lost your password?
                            </Link>
                          </div>
                          <div>
                            <Link style={{ color: "gray" }} to="/sign-up">
                              Create Account
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      )}
    </div>
  );
};

export default LoginForm;
