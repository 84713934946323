import React from "react";

const UserInfo = ({ user }) => {
  return (
    <div className="content">
      <table className="table">
        <tbody>
          <tr>
            <th scope="row">Username</th>
            <td>{user.username}</td>
          </tr>
          <tr>
            <th scope="row">Gender</th>
            <td>{user.gender}</td>
          </tr>
          <tr>
            <th scope="row">DOB</th>
            <td>{user.dob}</td>
          </tr>
          <tr>
            <th scope="row">Email</th>
            <td>{user.email}</td>
          </tr>
          <tr>
            <th scope="row">Phone</th>
            <td>{user.phone}</td>
          </tr>
          <tr>
            <th scope="row">Country</th>
            <td>Nepal</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default UserInfo;
