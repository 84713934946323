import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getContact } from "../../../redux/actions/contact/contactActions";

const TermsCondition = () => {
  const dispatch = useDispatch();

  const { contact, loading } = useSelector((state) => state.contact);

  useEffect(() => {
    dispatch(getContact());
  }, []);

  if (loading) {
    return "";
  }
  return (
    <>
      <section className="blog-details-area">
        <div className="container pb-lg-85">
          <div className="row justify-content-center">
            <div className="col-lg-11" data-aos="fade-up">
              <div className="blog-details-content-wrap">
                <div className="blog-details-item">
                  <div
                    className="details-wrapper details-wrapper-style1"
                    data-margin-bottom="38"
                  >
                    {contact && contact.termsConditions ? (
                      <p
                        className="content"
                        dangerouslySetInnerHTML={{
                          __html: contact.termsConditions,
                        }}
                      ></p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsCondition;
