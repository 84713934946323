import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { BASE_URL } from "../../../../constants/Constants";

const Catlv3 = ({ id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [cat, setCat] = useState();

  useEffect(() => {
    getCategoryById(id);
  }, [dispatch]);

  const sideNav = (id) => {
    navigate(`/books?category=${id}`);
  };

  const getCategoryById = async (id) => {
    axios
      .get(`${BASE_URL}/categories/${id}`)
      .then((result) => {
        if (result.status === 200) {
          setCat(result.data);
        }
      })
      .catch((err) => {
        setCat(err);
      });
  };

  return (
    <ul className="dropdown-menu dropdown-submenu">
      {cat?.child?.map(
        (data, index) =>
          id == data.parent && (
            <>
              <li key={index}>
                <a
                  className="dropdown-item fs-7"
                  onClick={() => {
                    sideNav(data.id);
                  }}
                >
                  {data.name}
                </a>
              </li>
            </>
          )
      )}
    </ul>
  );
};

export default Catlv3;
