import AOS from "aos";
import "aos/dist/aos.css";

import HomePage from "./components/pages/homepage/HomePage";
import { loadUser } from "./redux/actions/auth/authActions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Routes, Route } from "react-router-dom";
import Layout from "./layout/Layout";
import LoginForm from "./components/Account/LoginForm";
import Books from "./components/pages/books/Books";
import SingleBook from "./components/pages/singleBook/SingleBook";
import Cart from "./components/cart/Cart";
import BookOrder from "./components/order/BookOrder";
import Dashboard from "./components/pages/user-dash/Dashboard";
import PrivateRoute from "./private/PrivateRoute";
import NotFound from "./no-match/NotFound";
import Contact from "./components/pages/contact/Contact";
import EditAddress from "./components/pages/user-dash/mainContent-components/EditAddress";
import Policy from "./components/pages/policy/Policy";
import TermsCondition from "./components/pages/policy/TermsCondition";
import Library from "./components/pages/user-dash/mainContent-components/Library";
import ReadBook from "./components/ReadBook/ReadBook";
import ReadPage from "./components/ReadBook/ReadPage";
import RegisterForm from "./components/Account/RegisterForm";
import ForgotPassword from "./components/Account/ForgotPassword";
import ResetPassword from "./components/Account/ResetPassword";
import DataDelete from "./components/pages/data-delete/DataDelete";

function App() {
  let dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(loadUser());
    AOS.init();
  }, [dispatch]);
  return (
    <div className="wrapper">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<HomePage user={user} />} />
          <Route path="/data-delete" element={<DataDelete />} />
          <Route path="/books" element={<Books user={user} />} />
          <Route path="/book/:id" element={<SingleBook user={user} />} />

          {/* User Login and Register */}
          <Route path="/sign-in" element={<LoginForm />} />
          <Route path="/sign-up" element={<RegisterForm />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          <Route path="/contact" element={<Contact />} />

          <Route path="/our-policy" element={<Policy />} />
          <Route path="/terms-conditions" element={<TermsCondition />} />

          <Route element={<PrivateRoute />}>
            <Route path="/my-dashboard" element={<Dashboard user={user} />} />
            <Route
              path="/my-dashboard/address/:id"
              element={<EditAddress user={user} />}
            />
            <Route path="/cart" element={<Cart user={user} />} />

            <Route path="/library" element={<Library user={user} />} />

            <Route
              path="/my-dashboard/read/:id"
              element={<ReadPage user={user} />}
            />

            <Route path="/check-out" element={<BookOrder user={user} />} />
          </Route>
          {/* No Match Route */}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
