import React from "react";

const ContactMap = () => {
  return (
    <div className="mt-2">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3532.881717335395!2d85.29606027038247!3d27.690050451097104!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6eb42a055601fd1d!2sReadmore%20Publishers%20%26%20Distributors!5e0!3m2!1sen!2snp!4v1652258767787!5m2!1sen!2snp"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
      ></iframe>
    </div>
  );
};

export default ContactMap;
