import { linkUrl } from "../actions/linkUrl";
import {
  LOAD_TEACHER_INFO,
  REQUEST_TEACHER_INFO,
  FAIL_TEACHER_INFO,
} from "../actions/teacher/types";

const initialState = {
  teacher: {},
  isAuthenticated: false,
  isTeacher: false,
  error: "",
  loading: true,
  imgUrl: {},
};

export const teacherReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_TEACHER_INFO:
      return {
        ...state,
      };

    case LOAD_TEACHER_INFO:
      return {
        ...state,
        teacher: action.payload,
        isAuthenticated: true,
        isTeacher: true,
        imgUrl: linkUrl,
        loading: false,
      };

    case FAIL_TEACHER_INFO:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
