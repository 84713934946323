import axios from "axios";
import { CREATE_ORDER, FETCH_ORDER, ORDER_FAIL } from "./types";
import { linkUrl } from "../linkUrl";
import { toast } from "react-toastify";

export const createOrder = (cartId, orderReq) => async (dispatch) => {
  axios
    .post(`${linkUrl}/carts/checkout/${cartId}`, orderReq)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: CREATE_ORDER,
          success: true,
        });
        toast.success("Your Order Proceed Has Successful.");
      }
    })
    .catch((err) => {
      dispatch({
        type: ORDER_FAIL,
        payload: err,
      });
      toast.error("Oops, Something Went Wrong..!");
    });
};

// Fetch User Order
export const fetchOrder = (userId) => async (dispatch) => {
  await axios
    .get(`${linkUrl}/orders?user=${userId}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: FETCH_ORDER,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: ORDER_FAIL,
        payload: err,
      });
    });
};
