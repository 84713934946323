import React from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import Cookies from "js-cookie";

const ReadBook = ({ book }) => {
  const [param, setParam] = useState();

  useEffect(() => {
    const getURL = async (id) => {
      const token = localStorage.getItem("token");

      const resp = await axios
        .get(`http://server.bookzonenepal.com/read/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((data) => {
          console.log("Settng coolkie");
          setParam(data.data.path);
        })
        .catch((err) => {
          return;
        });
    };

    getURL(book.id);
  }, []);

  const { imgUrl } = useSelector((state) => state.teacher);
  Cookies.set("test", "test", { domain: "bookzonenepal.com" });
  return (
    <>
      <tr key={book.id}>
        <td className="product-thumbnail">
          {book &&
          book.thumbnail &&
          book.thumbnail.formats &&
          book.thumbnail.formats.thumbnail &&
          book.thumbnail.formats.thumbnail.url ? (
            <a href="#">
              <img
                src={`${imgUrl}${book?.thumbnail?.formats?.thumbnail?.url}`}
                alt=""
                width="50px"
              />
            </a>
          ) : (
            ""
          )}
        </td>
        <td>
          <a href="#">{book.title}</a>
        </td>
        <td>
          <a
            target="_blank"
            href={`http://server.bookzonenepal.com/read/${book.id}`}
            className="btn btn-sm btn-primary"
          >
            Read Book
          </a>
        </td>
      </tr>
    </>
  );
};

export default ReadBook;
