import { combineReducers } from "redux";
import { allBooksReducer } from "./allBooksReducer";
import { bannersReducer } from "./bannersReducer";
import { categoriesReducer } from "./categoriesReducer";
import { popularBooksReducer } from "./popularBooksReducer";
import { newArrivalsReducer } from "./newArrivalsReducer";
import { mostViewedReducer } from "./mostViewedReducer";
import { userReducer } from "./authReducer";
import { cartReducer } from "./cartReducer";
import { userLibraryReducer } from "./userLibraryReducer";
import { userOrdersReducer } from "./userOrdersReducer";
import { teacherReducer } from "./teacherReducers";
import { shippingInfoReducer } from "./shippingReducer";
import { adsReducers } from "./adsReducers";
import { reviewsReducer } from "./reviewsReducer";
import { contactReducer } from "./contactReducer";

export default combineReducers({
  banners: bannersReducer,
  categories: categoriesReducer,
  allBooks: allBooksReducer,
  popularBooks: popularBooksReducer,
  newArrivals: newArrivalsReducer,
  mostViewed: mostViewedReducer,
  user: userReducer,
  cart: cartReducer,
  userLibrary: userLibraryReducer,
  myOrders: userOrdersReducer,
  teacher: teacherReducer,
  shippingInfo: shippingInfoReducer,
  ads: adsReducers,
  reviews: reviewsReducer,
  contact: contactReducer,
});
