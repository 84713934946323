export const REQUEST_SHIPPING_INFO = "REQUEST_SHIPPING_INFO";
export const GET_SHIPPING_INFO = "GET_SHIPPING_INFO";
export const POST_SHIPPING_INFO = "POST_SHIPPING_INFO";
export const UPDATE_SHIPPING_INFO = "UPDATE_SHIPPING_INFO";

export const REQUEST_DELIVERY_ZONES = "REQUEST_DELIVERY_ZONES";
export const GET_DELIVERY_ZONES = "GET_DELIVERY_ZONES";

export const FAIL_SHIPPING_INFO = "FAIL_SHIPPING_INFO";

export const REQUEST_DELIVERY_RATES = "REQUEST_DELIVERY_RATES";
export const GET_DELIVERY_RATE = "GET_DELIVERY_RATE";
export const FAIL_DELIVERY_RATE = "FAIL_DELIVERY_RATE";

export const CALC_DELIVERY_CHARGE = "CALC_DELIVERY_CHARGE";

export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
