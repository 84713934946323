import axios from "axios";
import {
  GET_LIBRARY,
  DELETE_LIBRARY,
  FAIL_LIBRARY,
  GET_ERRORS,
  CLEAR_ERRORS,
  ADD_LIBRARY,
} from "./types";
import { linkUrl } from "../linkUrl";
import { toast } from "react-toastify";

export const addUserLibrary = (addLib) => async (dispatch) => {
  axios
    .post(`${linkUrl}/libraries`, addLib)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: ADD_LIBRARY,
          payload: result.data,
        });
        toast.success("Book Added To Library");
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      toast.error("Oops! Something Went Wrong !!");
    });
};

export const getUserLibrary = (userId) => async (dispatch) => {
  axios
    .get(`${linkUrl}/libraries?user=${userId}`)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: GET_LIBRARY,
          payload: result.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

// Delete Library

export const deleteUserLibrary = (libId, bookId) => async (dispatch) => {
  axios
    .delete(`${linkUrl}/libraries/${libId}?books=${bookId}`)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: DELETE_LIBRARY,
          payload: result.data,
        });
        toast.success("Book Removed From Library");
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      });
      toast.error("Oops! Something Went Wrong !!");
    });
};
