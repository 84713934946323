import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../loaderComponent/Loader";
import {
  loadUser,
  updateProfile,
} from "../../../../redux/actions/auth/authActions";

const EditUserInfo = () => {
  const dispatch = useDispatch();
  const { loading, user } = useSelector((state) => state.user);

  const [profile, setProfile] = useState({
    fullName: !user || user.fullName === undefined ? "" : user.fullName,
    email: user.email,
    dob: user.dob,
    phone: user.phone,
    address: user.address,
  });

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  useEffect(() => {
    setProfile({
      ...profile,
      fullName: user.fullName,
      email: user.email,
      dob: user.dob,
      phone: user.phone,
      address: user.address,
    });
  }, [user]);

  const handleChange = (e) => {
    setProfile({
      ...profile,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (user) {
      dispatch(updateProfile(profile, user.id));
    }
  };

  return (
    <div>
      <form action="#" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6">
            <div className="single-input-item">
              <label htmlFor="fullName" className="required">
                Full Name
              </label>
              <input
                type="text"
                name="fullName"
                id="fullName"
                value={profile.fullName}
                placeholder="User Name"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="single-input-item">
              <label htmlFor="email" className="required">
                Email Addres
              </label>
              <input
                type="email"
                id="email"
                value={profile.email}
                placeholder="Email"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
        </div>
        {/* <div className="single-input-item">
          <label htmlFor="display-name" className="required">
            Display Name
          </label>
          <input type="text" id="display-name" />
        </div> */}

        <div className="single-input-item">
          <label htmlFor="email" className="required">
            Address
          </label>
          <input
            type="text"
            name="address"
            id="address"
            value={profile.address}
            placeholder="1234 Main St"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="single-input-item">
          <label htmlFor="email" className="required">
            Phone Number
          </label>
          <input
            type="number"
            id="phone"
            name="phone"
            value={profile.phone}
            placeholder="Phone Number"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="single-input-item">
          <label htmlFor="email" className="required">
            Date of Birth
          </label>
          <input
            type="date"
            id="dob"
            name="dob"
            value={profile.dob}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="single-input-item">
          <label htmlFor="email" className="required">
            Gender
          </label>
          <input
            type="text"
            id="gender"
            name="gender"
            value={user.gender}
            onChange={(e) => handleChange(e)}
          />
        </div>

        <div className="single-input-item">
          <button className="check-btn sqr-btn">Save Changes</button>
        </div>
      </form>
    </div>
  );
};

export default EditUserInfo;
