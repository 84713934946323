import React from "react";

const AboutDesc = ({ contact }) => {
  return (
    <div className="contact-form-wrap">
      <div className="contact-form-title">
        <h6>Get office info.</h6>
      </div>
      {/* <p>{contact.aboutUs}</p> */}
      <div className="wn__addres__wreapper">
        <div className="single__address">
          <i className="icon-location-pin icons"></i>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: contact.aboutUs }}
          />
        </div>
      </div>
    </div>
  );
};

export default AboutDesc;
