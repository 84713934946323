import {
  GET_MOST_VIEWED,
  GET_ERRORS,
  CLEAR_ERRORS,
} from "../actions/mostViewed/types";
import { linkUrl } from "../actions/linkUrl";

const initialState = {
  mostViewed: [],
  imgUrl: {},
  errors: {},
  loading: true,
};

export const mostViewedReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MOST_VIEWED:
      return {
        ...state,
        mostViewed: action.payload,
        imgUrl: linkUrl,
        loading: false,
      };

    case GET_ERRORS:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
