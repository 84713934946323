import React, { useEffect, useState } from "react";
import UserInfo from "./mainContent-components/UserInfo";
import { loadUser } from "../../../redux/actions/auth/authActions";
import { useDispatch, useSelector } from "react-redux";
import Orders from "./mainContent-components/Orders";
import Library from "./mainContent-components/Library";
import PasswordChange from "./mainContent-components/PasswordChange";
import EditUserInfo from "./mainContent-components/EditUserInfo";
import TeacherBooks from "./mainContent-components/TeacherBooks";
import AcademicInfo from "./mainContent-components/AcademicInfo";
import Address from "./mainContent-components/Address";
import TeacherRequestForm from "../../TeacherRequestForm/TeacherRequestForm";
import { getTeacherInfo } from "../../../redux/actions/teacher/teacherActions";

const DashmainContent = () => {
  const dispatch = useDispatch();

  const { user, loading, isAuthenticated } = useSelector((state) => state.user);

  const { teacher } = useSelector((state) => state.teacher);

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTeacherInfo(user.id));
  }, [dispatch]);

  return (
    <div className="tab-content" id="nav-tabContent">
      <div
        className="tab-pane fade show active"
        id="dashboad"
        role="tabpanel"
        aria-labelledby="dashboad-tab"
      >
        <div className="myaccount-content">
          <h3>Dashboard</h3>
          {!teacher ? (
            <div class="alert alert-danger" role="alert">
              Create teacher's account by filling out the form.{" "}
              <TeacherRequestForm className="text-align-center" />
            </div>
          ) : null}
          <div className="welcome">
            <UserInfo user={user} />
          </div>
        </div>
      </div>
      <div
        className="tab-pane fade"
        id="orders"
        role="tabpanel"
        aria-labelledby="orders-tab"
      >
        <div className="myaccount-content">
          <h3>Orders</h3>
          <div className="myaccount-table table-responsive text-center">
            <Orders user={user} />
          </div>
        </div>
      </div>

      <div
        className="tab-pane fade"
        id="download"
        role="tabpanel"
        aria-labelledby="download-tab"
      >
        <div className="myaccount-content">
          <h3>My Library</h3>
          <div className="myaccount-table table-responsive text-center">
            <Library user={user} />
          </div>
        </div>
      </div>

      <div
        className="tab-pane fade"
        id="teacher-books"
        role="tabpanel"
        aria-labelledby="teacher-books-method-tab"
      >
        <div className="myaccount-content">
          <h3>Teacher Books</h3>
          <TeacherBooks />
        </div>
      </div>
      <div
        className="tab-pane fade"
        id="academic-info"
        role="tabpanel"
        aria-labelledby="academic-info-method-tab"
      >
        <div className="myaccount-content">
          <h3>Academic Information</h3>
          <div className="account-details-form">
            <AcademicInfo user={user} />
          </div>
        </div>
      </div>
      <div
        className="tab-pane fade"
        id="address-edit"
        role="tabpanel"
        aria-labelledby="address-edit-tab"
      >
        <div className="myaccount-content">
          <h3>Billing Address</h3>
          <Address user={user} isAuthenticated={isAuthenticated} />
        </div>
      </div>
      <div
        className="tab-pane fade"
        id="account-info"
        role="tabpanel"
        aria-labelledby="account-info-tab"
      >
        <div className="myaccount-content">
          <h3>Update Info</h3>
          <div className="account-details-form">
            <EditUserInfo />
          </div>
        </div>
      </div>

      {/* Password Change */}
      <div
        className="tab-pane fade"
        id="password-change"
        role="tabpanel"
        aria-labelledby="password-change-tab"
      >
        <div className="myaccount-content">
          <h3>Change Password</h3>
          <div className="account-details-form">
            <PasswordChange user={user} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashmainContent;
