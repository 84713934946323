import React, { useState } from "react";
import { searchBooks } from "../../../redux/actions/allBooks/allBooks";

import { useDispatch } from "react-redux";

import { useLocation, useNavigate } from "react-router-dom";

const HeaderSearch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");

  const handleChange = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };

  const location = useLocation();

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchValue !== "") {
      dispatch(searchBooks(searchValue));
      navigate(`/books?search_books=${searchValue}`);
    }
  };

  return (
    location.pathname !== "/contact" && (
      <div className="header-middle-align-center">
        <div className="header-search-area">
          <form onSubmit={handleSearch} className="header-searchbox">
            <input
              type="text"
              id="searchValue"
              name="searchValue"
              className="form-control"
              placeholder="Search Book"
              value={searchValue}
              onChange={(e) => handleChange(e)}
            />
            <button className="btn-submit" type="submit">
              <i className="pe-7s-search"></i>
            </button>
          </form>
        </div>
      </div>
    )
  );
};

export default HeaderSearch;
