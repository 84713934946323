import {
  GET_CATEGORIES,
  GET_CATEGORY,
  GET_SUB_CATEGORY,
  FAIL_CATEGORIES,
  GET_ERRORS,
  CLEAR_ERRORS,
  GET_CATLV,
  GET_CATLV3,
} from "../actions/bookCategories/types";
import { linkUrl } from "../actions/linkUrl";

const initialState = {
  categories: [],
  categorylv: [],
  categorylv3: [],
  category: {},
  subCategory: {},
  imgUrl: {},
  errors: {},
  loading: true,
};

export const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
        imgUrl: linkUrl,
        loading: false,
      };

    case GET_CATEGORY:
      return {
        ...state,
        category: action.payload,
        imgUrl: linkUrl,
        loading: false,
      };

    case GET_CATLV:
      return {
        ...state,
        categorylv: action.payload,
        imgUrl: linkUrl,
        loading: false,
      };

    case GET_CATLV3:
      return {
        ...state,
        categorylv3: action.payload,
        imgUrl: linkUrl,
        loading: false,
      };

    case GET_SUB_CATEGORY:
      return {
        ...state,
        subCategory: action.payload,
        imgUrl: linkUrl,
        loading: false,
      };

    case GET_ERRORS:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
