import axios from "axios";
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  CLEAR_ERRORS,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  LOGOUT,
} from "./types";
import { linkUrl } from "../linkUrl";
import { toast } from "react-toastify";

export const login = (user) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  await axios
    .post(`${linkUrl}/auth/local`, user, config)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: result.data.user,
        });
        localStorage.setItem("token", result.data.jwt);
      }
    })
    .catch((err) => {
      dispatch({
        type: LOGIN_FAIL,
        payload: err.response.data.message,
      });
    });
};

export const register = (user) => async (dispatch) => {
  dispatch({ type: REGISTER_USER_REQUEST });
  const config = {
    headers: {
      "Content-Type": "application/json",
      // "Content-Type": "multipart/form-data",
    },
  };
  await axios
    .post(`${linkUrl}/auth/local/register`, user, config)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: REGISTER_USER_SUCCESS,
          payload: result.data.user,
        });
        localStorage.setItem("token", result.data.jwt);
      }
    })
    .catch((err) => {
      dispatch({
        type: REGISTER_USER_FAIL,
        payload: err.response.data.message,
      });
    });
};

export const loadUser = () => async (dispatch) => {
  dispatch({ type: LOAD_USER_REQUEST });
  const token = localStorage.getItem("token");
  if (token) {
    await axios
      .get(`${linkUrl}/users/me`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: LOAD_USER_SUCCESS,
            payload: result.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: LOAD_USER_FAIL,
          payload: err.response.data.message,
        });
      });
  }
};

// Update User Profile
export const updateProfile = (userProfile, userId) => async (dispatch) => {
  dispatch({ type: UPDATE_PROFILE_REQUEST });
  await axios
    .put(`${linkUrl}/users/${userId}`, userProfile)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: UPDATE_PROFILE_SUCCESS,
          payload: result.data.user,
        });
        toast.success("Data Updated Successfully");
      }
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_PROFILE_FAIL,
        payload: err.response.data.message,
      });
      toast.error("Oops!, Something Went Wrong");
    });
};

// Update Password
export const updatePassword = (user) => async (dispatch) => {
  dispatch({ type: UPDATE_PASSWORD_REQUEST });
  await axios
    .put(`${linkUrl}/auth/local`, user)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: UPDATE_PASSWORD_SUCCESS,
          payload: result.data.user,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_PASSWORD_FAIL,
        payload: err.response.data.message,
      });
    });
};

// Logout

export const logout = () => async (dispatch) => {
  localStorage.removeItem("token");
  dispatch({ type: LOGOUT });
};

// clear Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};

// Setup config/headers and token
export const tokenConfig = () => {
  // Get token from localstorage
  const token = localStorage.getItem("token");

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // If toekn, add to headers
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
};
