import React, { useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick/lib/slider";
import { getAllBooks } from "../../../redux/actions/allBooks/allBooks";

const RelatedProducts = ({ categoryID }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { books, loading, imgUrl } = useSelector((state) => state.allBooks);

  useEffect(() => {
    dispatch(getAllBooks("", 10));
  }, []);

  let RelatedBooks;
  if (loading) {
    return "";
  }

  RelatedBooks =
    // books &&
    books.map((book, idx) => {
      if (book.category.id == categoryID) {
        return (
          <div className="swiper-slide" key={idx}>
            <div className="product">
              <div className="product__thumb">
                <a className="first__img" href={`/book/${book.id}`}>
                  <img
                    src={
                      book.thumbnail === null
                        ? "/assets/images/logo/readmore-logo.png"
                        : `${imgUrl}${book?.thumbnail?.formats?.small?.url}`
                    }
                    alt="product image"
                    style={{
                      maxHeight: "190px",
                      objectFit: "cover",
                      aspectRatio: "1/2",
                    }}
                    width="80%"
                  />
                </a>
              </div>
              <div className="product__content">
                <h4>
                  <a href={`/book/${book.id}`} style={{ fontSize: "0.55em" }}>
                    {book.title}
                  </a>
                </h4>
                <ReactStars
                  edit={false}
                  color={"rgba(20, 20, 20, 0.1)"}
                  activeColor={"#FFD167"}
                  size={window.innerWidth < 600 ? 18 : 20}
                  value={book.average_rating}
                  isHalf={true}
                />
                <span className="price-label" style={{ color: "#0066B3" }}>
                  Price NRs.
                </span>
                <span className="price" style={{ color: "#0E86D4" }}>
                  {book.price}
                </span>
              </div>
            </div>
          </div>
        );
      }
    });

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "red" }}
        onClick={onClick}
      />
    );
  }

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="product-area product-best-seller-area">
      <div className="container pt--0">
        <div className="row">
          <div className="col-12">
            <div className="section-title text-center">
              <h3 className="title">Related Products</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Slider {...settings}>
              {/* <!-- Start Single Product --> */}
              {RelatedBooks}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RelatedProducts;
