import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BillingAddress from "./BillingAddress";
import {
  clearTotal,
  getCartTotal,
  getItemsFromCart,
} from "../../redux/actions/cart/cartAction";

import { createOrder } from "../../redux/actions/order/orderAction";

import {
  getDeliveryRates,
  getDeliveryZones,
  calcDeliveryCharge,
  fetchShippingAddress,
} from "../../redux/actions/shipping/shippingInfo";
import Loader from "../loaderComponent/Loader";
import { useNavigate } from "react-router-dom";
import { CLEAR_CART } from "../../redux/actions/cart/types";

const BookOrder = ({ user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { arrCartItems, totalItems, totalAmount, totalWeight, loading } =
    useSelector((state) => state.cart);

  const { rates, deliveryCharge, shippingInfo } = useSelector(
    (state) => state.shippingInfo
  );
  const [checked, setChecked] = useState(false);
  const { success } = useSelector((state) => state.myOrders);

  const [orderReq, setOrderReq] = useState({
    payment_type: "cod",
    shipping_info: "",
    sub_total: "",
    delivery_charge: "",
    transaction_id: "",
  });

  useEffect(() => {
    dispatch(getItemsFromCart());
  }, [dispatch]);

  useEffect(() => {
    // if (success === true) {
    //   navigate("/");
    // }
    getRandom();
  }, [success === true]);

  // Generate Random Number
  const getRandom = () => {
    return Math.floor(100000000 + Math.random() * 900000000);
  };
  let ranNum = getRandom();

  useEffect(() => {
    dispatch(fetchShippingAddress(user.id));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getItemsFromCart(user.id));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCartTotal(arrCartItems));
  }, [dispatch, arrCartItems]);

  useEffect(() => {
    dispatch(getDeliveryRates());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDeliveryZones());
  }, [dispatch]);

  const getShippingAddress = (shipAdd) => {
    setOrderReq({
      ...orderReq,
      shipping_info: shipAdd && shipAdd.id ? shipAdd.id : "",
      sub_total: !totalAmount ? "" : totalAmount,
      delivery_charge: !deliveryCharge ? 0 : deliveryCharge,
      transaction_id: ranNum.toString(),
    });
  };

  if (loading) {
    return "";
  }

  const handleOrder = (e, id) => {
    if (orderReq.shipping_info === "" || orderReq.shipping_info === undefined) {
      return toast.error("Please Provide Your Shipping Address");
    } else {
      dispatch(createOrder(id, orderReq));
      dispatch(clearTotal());
    }

    dispatch(getItemsFromCart());
    dispatch(clearTotal());

    setTimeout(() => {
      navigate("/");
    }, 2000);
  };

  let orderItems =
    !arrCartItems || arrCartItems.cart_items === undefined ? (
      <li>Your Cart Is Empty!!! </li>
    ) : (
      arrCartItems.cart_items.map((cartItem, idx) => {
        return (
          <tr className="cart-item" key={idx}>
            <td className="product-name">
              {cartItem.book.title}{" "}
              <span className="product-quantity">× {cartItem.item}</span>
            </td>
            <td className="product-total">
              NRs.{cartItem.book.price * cartItem.item}
            </td>
          </tr>
        );
      })
    );

  return (
    <section className="shopping-checkout-wrap">
      {loading ? (
        <Loader />
      ) : (
        <>
          <ToastContainer autoClose={2000} />
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="checkout-billing-details-wrap">
                  <h2 className="title">Billing details</h2>
                  <div className="billing-form-wrap">
                    <BillingAddress
                      user={user}
                      totalWeight={totalWeight}
                      getShippingAddress={getShippingAddress}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="checkout-order-details-wrap">
                  <div className="order-details-table-wrap table-responsive">
                    <h2 className="title mb-25">Your order</h2>
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="product-name">Product</th>
                          <th className="product-total">Total</th>
                        </tr>
                      </thead>
                      <tbody className="table-body">{orderItems}</tbody>
                      <tfoot className="table-foot">
                        <tr className="cart-subtotal">
                          <th>Subtotal</th>
                          <td>NRs.{totalAmount}</td>
                        </tr>
                        <tr className="shipping">
                          <th>Delivery Charge</th>
                          <td>NRs.{deliveryCharge ? deliveryCharge : 0}</td>
                        </tr>
                        <tr className="order-total">
                          <th>Total </th>
                          <td>
                            NRs.
                            {deliveryCharge
                              ? totalAmount + deliveryCharge
                              : totalAmount}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                    <div className="shop-payment-method">
                      <div id="PaymentMethodAccordion">
                        <div className="card">
                          <div className="card-header" id="check_payments3">
                            <h5
                              className="title"
                              data-bs-toggle="collapse"
                              data-bs-target="#itemThree"
                              aria-controls="itemTwo"
                              aria-expanded="false"
                              onClick={() => setChecked(!checked)}
                            >
                              Cash on delivery
                            </h5>
                          </div>
                          <div
                            id="itemThree"
                            className="collapse"
                            aria-labelledby="check_payments3"
                            data-bs-parent="#PaymentMethodAccordion"
                          >
                            <div className="card-body">
                              <p>Pay with cash upon delivery.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <a
                        type="button"
                        className="btn-theme"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          !checked
                            ? toast.warn("Please select payment option")
                            : handleOrder(
                                e,
                                !arrCartItems ? "" : arrCartItems.id
                              );
                        }}
                      >
                        Place order
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default BookOrder;
