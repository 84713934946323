import {
  GET_NEW_ARRIVALS,
  GET_ERRORS,
  CLEAR_ERRORS,
} from "../actions/newArrival/types";
import { linkUrl } from "../actions/linkUrl";

const initialState = {
  newArrivals: [],
  imgUrl: {},
  errors: {},
  loading: true,
};

export const newArrivalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NEW_ARRIVALS:
      return {
        ...state,
        newArrivals: action.payload,
        imgUrl: linkUrl,
        loading: false,
      };

    case GET_ERRORS:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
