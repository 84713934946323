import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Loader from "../components/loaderComponent/Loader";
import { loadUser } from "../redux/actions/auth/authActions";

const PrivateRoute = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isAuthenticated, user, loading } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(loadUser());
  }, []);

  if (loading) {
    return <Loader />;
  }

  return isAuthenticated === true ? (
    <Outlet />
  ) : (
    <Navigate to="/sign-in" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
