import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchShippingAddress,
  getDeliveryZones,
  getDeliveryRates,
  postShippingAddress,
  calcDeliveryCharge,
} from "../../redux/actions/shipping/shippingInfo";

const BillingAddress = ({ user, totalWeight, getShippingAddress }) => {
  const dispatch = useDispatch();
  const { shippingInfo, zones, loading, rates } = useSelector(
    (state) => state.shippingInfo
  );

  // Optional Address Form State
  const [shippingAddress, setShippingAddress] = useState({
    user: user.id,
    phone: "",
    address: "",
    email: "",
    delivery_zone: "",
  });

  const [radioAddress, setRadionAddress] = useState({
    delivery_zone: "",
  });

  const [primaryAddrss, setPrimaryAddrss] = useState();

  useEffect(() => {
    if (user) {
      dispatch(fetchShippingAddress(user.id));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user) {
      dispatch(getDeliveryZones(user.id));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (shippingInfo) {
      shippingInfo.map((shipping) => {
        if (shipping.primary === true) {
          setPrimaryAddrss(shipping);
          setRadionAddress({
            ...radioAddress,
            delivery_zone: shipping.delivery_zone.zone,
          });
        }
      });
    }
    getShippingAddress(primaryAddrss);
  }, [shippingInfo]);

  // Calcutating Delivery Charge
  useEffect(() => {
    dispatch(calcDeliveryCharge(rates, radioAddress, totalWeight));
  }, [dispatch, rates, radioAddress, totalWeight]);

  const handleChange = (e) => {
    e.preventDefault();
    setShippingAddress({
      ...shippingAddress,
      [e.target.id]: e.target.value,
    });
  };

  const handleChangeRadio = (e) => {
    setRadionAddress({ ...radioAddress, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(postShippingAddress(shippingAddress));
  };

  let deliveryAddress;
  if (loading) {
    return "";
  }

  if (shippingInfo || shippingInfo !== undefined) {
    deliveryAddress = shippingInfo.map((shipping, idx) => (
      <div className="form-check" key={idx}>
        <input
          className="form-check-input"
          type="radio"
          name="delivery_zone"
          id={shipping.id}
          value={shipping.delivery_zone.id}
          defaultChecked={shipping.primary === true ? true : false}
          onChange={(e) => handleChangeRadio(e)}
        />
        <label className="form-check-label" htmlFor={shipping.address}>
          <span style={{ fontSize: "18px", fontWeight: "500" }}>
            Address: {shipping.address}
          </span>
          <h6 style={{ fontSize: "14px", fontWeight: "700" }}>
            Phone: {shipping.phone}
          </h6>
        </label>
      </div>
    ));
  }

  return (
    <>
      <div className="row mb-4">{deliveryAddress}</div>

      <h1>Shipping Address</h1>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="col-md-12">
          <div className="form-group" data-margin-bottom="30">
            <label htmlFor="email">
              Email
              <abbr className="required" title="required">
                *
              </abbr>
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="form-control"
              placeholder="abc@example.com"
              value={shippingAddress.email || ""}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group" data-margin-bottom="30">
            <label htmlFor="phone">
              Contact Number{" "}
              <abbr className="required" title="required">
                *
              </abbr>
            </label>
            <input
              type="text"
              name="phone"
              id="phone"
              className="form-control"
              placeholder="contact number"
              value={shippingAddress.phone || ""}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group" data-margin-bottom="30">
            <label htmlFor="address">
              Address
              <abbr className="required" title="required">
                *
              </abbr>
            </label>
            <input
              type="text"
              placeholder="Street address"
              name="address"
              id="address"
              className="form-control"
              value={shippingAddress.address || ""}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="delivery_zone">
              Zone
              <abbr className="required" title="required">
                *
              </abbr>
            </label>
            <select
              className="form-control"
              id="delivery_zone"
              name="delivery_zone"
              onChange={(e) => handleChange(e)}
            >
              <option>Select a Zone</option>
              {(zones && zones === undefined) || zones.length <= 0
                ? ""
                : zones.map((zone, idx) => (
                    <option key={idx} value={zone.id}>
                      {zone.address}
                    </option>
                  ))}
            </select>
          </div>
        </div>
        <button type="submit" className="btn btn-sm btn-success">
          Add Address
        </button>
      </form>
    </>
  );
};

export default BillingAddress;
