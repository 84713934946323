import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getContact } from "../../../redux/actions/contact/contactActions";

import AboutDesc from "./AboutDesc";
import ContactForm from "./ContactForm";
import ContactInfo from "./ContactInfo";
import ContactMap from "./ContactMap";

const Contact = () => {
  const dispatch = useDispatch();

  const { contact, loading } = useSelector((state) => state.contact);

  useEffect(() => {
    dispatch(getContact());
  }, []);

  if (loading) {
    return "";
  }
  return (
    <main className="main-content">
      <ContactMap />

      {/* contact area wrapper */}
      <section className="contact-area contact-page-area">
        <div className="container">
          <div className="row contact-page-wrapper">
            <div className="col-xl-6">
              <ContactForm />
            </div>
            <div className="col-xl-6">
              <ContactInfo contact={contact} />
              <AboutDesc contact={contact} />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Contact;
