import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { BASE_URL } from "../../constants/Constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ResetPassword = () => {
  const [formDetails, setFormDetails] = useState({
    password: "",
    confirmPassword: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);

  let { search } = useLocation();

  const history = useNavigate();

  useEffect(() => {
    const passwordTimeout = setTimeout(() => {
      if (formDetails.password !== formDetails.confirmPassword) {
        setIsPasswordMatch(false);
      } else {
        setIsPasswordMatch(true);
      }
    }, 500);

    return () => {
      clearTimeout(passwordTimeout);
    };
  }, [formDetails]);

  const handleChange = (e) => {
    setFormDetails({
      ...formDetails,
      [e.target.id]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    axios
      .post(BASE_URL + "/auth/reset-password", {
        code: new URLSearchParams(search).get("code"),
        password: formDetails.password,
        passwordConfirmation: formDetails.confirmPassword,
      })
      .then(() => {
        toast.success("Successful");
        setTimeout(() => {
          history("/sign-in");
        }, 2000);
      })
      .catch((err) => toast.error("Something went wrong! Please try again."))
      .finally(() => setIsLoading(false));
  };
  return (
    <div>
      <ToastContainer autoClose={2000} />
      <main className="main-content">
        <div
          className="page-header-area parallax"
          style={{
            backgroundImage: `url(/assets/images/banner.jpg)`,
            backgroundBlendMode: "multiply",
            backgroundColor: "grey",
          }}
        >
          <div className="container pt--0 pb--0">
            <div className="row">
              <div className="col-12">
                <div className="page-header-content">
                  <h2
                    className="title"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                    style={{ color: "#fff", textShadow: "1px 1px 2px red" }}
                  >
                    Reset Password
                  </h2>
                  <nav
                    className="breadcrumb-area"
                    data-aos="fade-down"
                    data-aos-duration="1200"
                  >
                    <ul
                      className="breadcrumb"
                      style={{
                        color: "#fff",
                        textShadow: "1px 1px 2px black",
                      }}
                    >
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-sep">/</li>
                      <li>Reset Password</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="account-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="login-form-content">
                  <form onSubmit={onSubmit}>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="password">
                            Password
                            <span className="required">*</span>
                          </label>
                          <input
                            id="password"
                            name="password"
                            className="form-control"
                            placeholder="********"
                            type="password"
                            value={formDetails.password}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="confirmPassword">
                            Confirm Password
                            <span className="required">*</span>
                          </label>
                          <input
                            id="confirmPassword"
                            name="confirmPassword"
                            className="form-control"
                            placeholder="********"
                            type="password"
                            value={formDetails.confirmPassword}
                            onChange={(e) => handleChange(e)}
                          />
                          {!isPasswordMatch ? (
                            <div className="text-danger">
                              Both password must match
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <button
                            disabled={isLoading || !isPasswordMatch}
                            type="submit"
                            className="btn-login"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ResetPassword;
