import React from "react";

const ContactInfo = ({ contact }) => {
  return (
    <div className="contact-form-wrap">
      <div className="contact-form-title">
        <h2 className="title">Get office info.</h2>
      </div>
      {/* <p>{contact.aboutUs}</p> */}
      <div className="wn__addres__wreapper">
        <div className="single__address">
          <i className="icon-location-pin icons"></i>
          <div className="content">
            <span>Address:</span>
            <p>{contact.address}</p>
          </div>
        </div>

        <div className="single__address">
          <i className="icon-phone icons"></i>
          <div className="content">
            <span>Phone Number:</span>
            <p>{contact.phone}</p>
          </div>
        </div>

        <div className="single__address">
          <i className="icon-envelope icons"></i>
          <div className="content">
            <span>Email address:</span>
            <p>{contact.email}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
