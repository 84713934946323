import React from "react";

import Books from "./all Books/Books";
import Banner from "./Banner/Banner";
import Category from "./category/Category";

const HomePage = () => {
  return (
    <main className="main-content">
      <Banner />
      <Category />
      <Books />
    </main>
  );
};

export default HomePage;
