import React from "react";

const ReadPage = () => {
  return (
    <>
      <iframe
        src="http://server.bookzonenepal.com/exports/math_d6550da6e6/index.html#p=1"
        width={"100%"}
        height={"500px"}
      />
    </>
  );
};

export default ReadPage;
