import {
  GET_ALL_BOOKS_REQUEST,
  GET_ALL_BOOKS,
  FAIL_BOOKS,
  GET_ERRORS,
  CLEAR_ERRORS,
  GET_BOOK_BY_ID,
  FILTER_BOOKS_BY_CATEGORY,
  FILTER_BOOKS_BY_CATEGORY_ID,
  FILTER_BOOKS_BY_TAGS,
  GET_POPULAR_BOOKS,
  MOST_VIEWED_BOOK,
  GET_MOST_VIEWED,
  SEARCH_BOOKS,
  GET_NEW_ARRIVALS,
} from "../actions/allBooks/types";
import { linkUrl } from "../actions/linkUrl";

const initialState = {
  books: [],
  newArrival: [],
  mostViewed: [],
  filterBooks: [],
  filterBooksByCat: [],

  book: {},
  category: "",
  tag: "",
  imgUrl: {},
  errors: {},
  loading: true,
};

export const allBooksReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_BOOKS_REQUEST:
      return {
        ...state,
      };
    case GET_ALL_BOOKS:
      return {
        ...state,
        books: action.payload,
        filterBooks: action.payload,
        imgUrl: linkUrl,
        loading: false,
      };

    case GET_BOOK_BY_ID:
      return {
        ...state,
        book: action.payload,
        imgUrl: linkUrl,
        loading: false,
      };

    case FILTER_BOOKS_BY_CATEGORY:
      return {
        ...state,
        filterBooksByCat: action.payload,
        imgUrl: linkUrl,
        loading: false,
      };

    case SEARCH_BOOKS:
      return {
        ...state,
        filterBooksByCat: action.payload,
        imgUrl: linkUrl,
        loading: false,
      };

    case GET_POPULAR_BOOKS:
      return {
        ...state,
        filterBooksByCat: action.payload,
        imgUrl: linkUrl,
        loading: false,
      };

    // case FILTER_BOOKS_BY_TAGS:
    //   return {
    //     ...state,
    //     filterBooks: action.payload.items,
    //     tag: action.payload.tag,
    //   };

    case MOST_VIEWED_BOOK:
      return {
        ...state,
        filterBooksByCat: action.payload,
        mostViewed: action.payload,
        imgUrl: linkUrl,
        loading: false,
      };

    case GET_NEW_ARRIVALS:
      return {
        ...state,
        filterBooksByCat: action.payload,
        newArrival: action.payload,
        imgUrl: linkUrl,
        loading: false,
      };

    case FAIL_BOOKS:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
