import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CLEAR_CART,
  INCREMENT,
  DECREMENT,
  GET_TOTAL,
  GET_CART_ITEMS,
  REQUEST_CART_ITEMS,
} from "../actions/cart/types";
import { linkUrl } from "../actions/linkUrl";

// const cartFromLocalStorage = JSON.parse(
//   localStorage.getItem("cartItems") || "[]"
// );

const initialState = {
  arrCartItems: [],
  imgUrl: linkUrl,
  message: null,
  totalItems: "",
  totalAmount: 0,
  totalWeight: 0,
  loading: true,
};

export const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_CART_ITEMS:
      return {
        ...state,
      };
    case GET_CART_ITEMS:
      return {
        ...state,
        arrCartItems: action.payload,
        loading: false,
      };
    case ADD_TO_CART:
      return {
        ...state,
        cartItems: action.payload,
        message: action.payload.message,
        loading: false,
      };

    case REMOVE_FROM_CART:
      return {
        ...state,
        arrCartItems: action.payload.cartItems,
        // cartItems: state.cart.filter((c) => c.id !== action.payload.item.id),
      };
    case CLEAR_CART:
      return {
        ...state,
        cart: [],
      };
    case INCREMENT:
      return {
        ...state,
        cartItems: action.payload.cartItems,
      };
    case DECREMENT:
      return {
        ...state,
        cartItems: action.payload.cartItems,
      };

    case GET_TOTAL:
      return {
        ...state,
        totalItems: action.payload.totalItems,
        totalAmount: action.payload.totalAmount,
        totalWeight: action.payload.totalWeight,
      };

    case "CLEAR_TOTAL":
      return {
        ...state,
        totalAmount: 0,
      };

    default:
      return state;
  }
};
