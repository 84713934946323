import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchShippingAddress } from "../../../../redux/actions/shipping/shippingInfo";
import Loader from "../../../loaderComponent/Loader";

const Address = ({ user, isAuthenticated }) => {
  const dispatch = useDispatch();
  const { shippingInfo, loading } = useSelector((state) => state.shippingInfo);

  useEffect(() => {
    if (isAuthenticated === true && user) {
      dispatch(fetchShippingAddress(user.id));
    }
  }, [dispatch, isAuthenticated, user]);


  let address =
    shippingInfo &&
    shippingInfo.map((shippingAddress, idx) => (
      <div className="col-lg-4" key={idx}>
        <address>
          <p>
            <strong>{shippingAddress.delivery_zone.address}</strong>
          </p>
          <p>{shippingAddress.address}</p>
          <p>Mobile: {shippingAddress.phone}</p>
          <p style={{ fontSize: "12px", color: "green" }}>
            Email:
            {shippingAddress.email}
          </p>
        </address>
        <a
          href={`/my-dashboard/address/${shippingAddress.id}`}
          className="check-btn sqr-btn"
        >
          <i className="fa fa-edit"></i> Edit Address
        </a>
      </div>
    ));

  return <div className="row">{loading ? <Loader /> : address}</div>;
};

export default Address;
