export const GET_ALL_BOOKS_REQUEST = "GET_ALL_BOOKS_REQUEST";
export const GET_ALL_BOOKS = "GET_ALL_BOOKS";
export const FAIL_BOOKS = "FAIL_BOOKS";
export const GET_ERRORS = "GET_ERRORS";

export const GET_BOOK_BY_ID = "GET_BOOK_BY_ID";

export const GET_MOST_VIEWED = "GET_MOST_VIEWED";
export const FAIL_NEW_ARRIVAL_BOOK = "FAIL_NEW_ARRIVAL_BOOK";

export const MOST_VIEWED_BOOK = "MOST_VIEWED";
export const FAIL_MOST_VIEWED_BOOK = "FAIL_MOST_VIEWED_BOOK";

export const SEARCH_BOOKS = "SEARCH_BOOKS";
export const GET_POPULAR_BOOKS = "GET_POPULAR_BOOKS";
export const FILTER_BOOKS_BY_CATEGORY_ID = "FILTER_BOOKS_BY_CATEGORY_ID";
export const FILTER_BOOKS_BY_CATEGORY = "FILTER_BOOKS_BY_CATEGORY";
export const FILTER_BOOKS_BY_TAGS = "FILTER_BOOKS_BY_TAGS";

export const GET_NEW_ARRIVALS = "GET_NEW_ARRIVALS";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
