import React, { useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { getNewArrivals } from "../../../../redux/actions/allBooks/allBooks";
import Image from "../../../Image/Image";

const NewArrivals = () => {
  const dispatch = useDispatch();
  const { loading, newArrivals, imgUrl } = useSelector(
    (state) => state.newArrivals
  );

  let trueData = "true";

  useEffect(() => {
    dispatch(getNewArrivals(trueData));
  }, [dispatch, trueData]);

  let newArrivalsBook;
  if (loading) {
    return <h1>Loading...</h1>;
  } else {
    newArrivalsBook =
      newArrivals &&
      newArrivals.map((book, idx) => (
        <div className="slide-product d-felx justify-content-center" key={idx}>
          <div className="product-item">
            <div className="inner-content">
              <div className="product-thumb">
                <a href={`/book/${book.id}`}>
                  <Image
                    imageUrl={book?.thumbnail?.formats?.small?.url}
                    alt="Image-HasTech"
                  />
                </a>
                <div className="product-action">
                  <a className="btn-product-wishlist" href="shop-wishlist.html">
                    <i className="fa fa-heart"></i>
                  </a>
                  <a className="btn-product-cart" href="shop-cart.html">
                    <i className="fa fa-shopping-cart"></i>
                  </a>
                </div>
                <a className="banner-link-overlay" href="shop.html"></a>
              </div>
              <div className="product-info">
                <div className="category">
                  <ul>
                    <li>
                      <ReactStars
                        edit={false}
                        color={"rgba(20, 20, 20, 0.1)"}
                        activeColor={"#FFD167"}
                        size={window.innerWidth < 600 ? 18 : 20}
                        value={book.average_rating}
                        isHalf={true}
                      />
                    </li>
                  </ul>
                </div>
                <h4 className="title">
                  <a href="single-product.html">{book.title}</a>
                </h4>
                <div className="prices">
                  <span className="price">NRs.{book.price}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
  }

  // Carousel Settings
  // let i = !newArrivals || newArrivals.length <= 5 ? false : true;
  const settings = {
    infinite: true,
    slidesToShow: 5,
    speed: 3000,
    autoplaySpeed: 3000,
    autoplay: true,
    rows: 2,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          rows: 1,
          slidesPerRow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          rows: 1,
          slidesPerRow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          rows: 1,
          slidesPerRow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
          slidesPerRow: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {/* <!-- Start Single Product --> */}
      {newArrivalsBook}
    </Slider>
  );
};

export default NewArrivals;
