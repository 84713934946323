import React from "react";

const QuickViewMenu = () => {
  return (
    <aside className="product-quick-view-modal">
      <div className="product-quick-view-inner">
        <div className="product-quick-view-content">
          <button type="button" className="btn-close">
            <span className="close-icon">
              <i className="fa fa-close"></i>
            </span>
          </button>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="thumb">
                <img
                  src="assets/img/shop/product-single/1.webp"
                  width="570"
                  height="541"
                  alt="Alan-Shop"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12"></div>
          </div>
        </div>
      </div>
      <div className="canvas-overlay"></div>
    </aside>
  );
};

export default QuickViewMenu;
