import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getContact } from "../../../redux/actions/contact/contactActions";
import HeaderCart from "./HeaderCart";
import HeaderSearch from "./HeaderSearch";

const Header = ({ user, isAuthenticated }) => {
  const dispatch = useDispatch();
  const { contact, loading } = useSelector((state) => state.contact);

  useEffect(() => {
    dispatch(getContact());
  }, [dispatch]);

  let auth =
    isAuthenticated === true ? (
      <li className="account">
        <i className="fa fa-user"></i>
        <Link to="/my-dashboard">{user.username}</Link>
      </li>
    ) : (
      <li className="account">
        <i className="fa fa-user"></i>
        <Link to="/sign-in">Log In</Link>
      </li>
    );

  return (
    <>
      <header className="main-header-wrapper">
        <div className="header-top">
          <div className="container pt--0 pb--0">
            <div className="row">
              <div className="col-12">
                <div className="header-top-align">
                  <div className="header-top-align-start">
                    <div className="desc">
                      {/* <p>World Wide Completely Free Returns and Free Shipping</p> */}
                    </div>
                  </div>
                  <div className="header-top-align-end">
                    <div className="header-info-items">
                      <div className="info-items">
                        <ul>
                          <li className="number">
                            <i className="fa fa-phone"></i>
                            <a href={`tel:${contact.phone}`}>
                              {!contact.phone ? "" : contact.phone}
                            </a>
                          </li>
                          <li className="email">
                            <i className="fa fa-envelope"></i>
                            <a href={`mailto:${contact.email}`}>
                              {!contact.email ? "" : contact.email}
                            </a>
                          </li>
                          {auth}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-middle">
          <div className="container pt--0 pb--0">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="header-middle-align">
                  <div className="header-middle-align-start">
                    <div className="header-logo-area">
                      <Link to="/">
                        <img
                          className="logo-main"
                          src="/assets/images/logo/logo.png"
                          width="131"
                          height="34"
                          alt="Logo"
                        />
                      </Link>
                    </div>
                  </div>
                  <HeaderSearch />
                  <HeaderCart user={user} isAuthenticated={isAuthenticated} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="header-area header-default">
          <div className="container">
            <div className="row no-gutter align-items-center ">
              <div className="col-12">
                <div className="header-align">
                  <div className="header-navigation-area ">
                    <ul className="main-menu nav navbar sticky-top">
                      <li>
                        <Link to="/">
                          <span>Home</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/books">
                          <span>Books</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact">
                          <span>Contact</span>
                        </Link>
                      </li>
                      {isAuthenticated && (
                        <li>
                          <Link to="/library">
                            <span>Library</span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
