import {
  CREATE_ORDER,
  FETCH_ORDER,
  ORDER_FAIL,
  DELETE_ORDER,
} from "../actions/order/types";
import { linkUrl } from "../actions/linkUrl";

const initialState = {
  orders: [],
  imgUrl: {},
  errors: {},
  success: false,
  loading: true,
};

export const userOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDER:
      return {
        ...state,
        orders: action.payload,
        imgUrl: linkUrl,
        loading: false,
      };

    case CREATE_ORDER:
      return {
        ...state,
        success: action.success,
        loading: false,
      };

    default:
      return state;
  }
};
