import {
  GET_SHIPPING_INFO,
  REQUEST_SHIPPING_INFO,
  GET_ERRORS,
  CLEAR_ERRORS,
  GET_DELIVERY_ZONES,
  POST_SHIPPING_INFO,
  REQUEST_DELIVERY_RATES,
  GET_DELIVERY_RATE,
  CALC_DELIVERY_CHARGE,
} from "../actions/shipping/types";
import { linkUrl } from "../actions/linkUrl";

const initialState = {
  shippingInfo: [],
  zones: [],
  rates: [],
  deliveryCharge: 0,
  imgUrl: {},
  errors: {},
  loading: true,
};

export const shippingInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_SHIPPING_INFO:
      return {
        ...state,
      };

    case GET_SHIPPING_INFO:
      return {
        ...state,
        shippingInfo: action.payload,
        imgUrl: linkUrl,
        loading: false,
      };

    case POST_SHIPPING_INFO:
      return {
        ...state,
        loading: false,
      };

    case GET_DELIVERY_ZONES:
      return {
        ...state,
        zones: action.payload,
        loading: false,
      };
    case GET_DELIVERY_RATE:
      return {
        ...state,
        rates: action.payload,
        loading: false,
      };

    case CALC_DELIVERY_CHARGE:
      return {
        ...state,
        deliveryCharge: action.payload,
        loading: false,
      };

    case GET_ERRORS:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
