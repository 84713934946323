import axios from "axios";
import { FAIL_SUBMIT, SUBMIT_FORM } from "./types";
import { linkUrl } from "../linkUrl";
import { toast } from "react-toastify";

export const submitForm = (formData) => async (dispatch) => {
  await axios
    .post(`${linkUrl}/contact-forms`, formData)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: SUBMIT_FORM,
        });
        toast.success("Thank You, We'll Respond You Soon...");
      }
    })
    .catch((err) => {
      dispatch({
        type: FAIL_SUBMIT,
      });
      toast.error("Oops! Something Went Wrong..");
    });
};
