import axios from "axios";
import { GET_BANNERS, GET_ERRORS, CLEAR_ERRORS } from "./types";
import { linkUrl } from "../linkUrl";

export const getBanners = () => async (dispatch) => {
  axios
    .get(`${linkUrl}/banners`)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: GET_BANNERS,
          payload: result.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

// clear Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
