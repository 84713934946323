export const REQUEST_CART_ITEMS = "REQUEST_CART_ITEMS";
export const GET_CART_ITEMS = "GET_CART_ITEMS";
export const FAIL_CART_ITEMS = "GET_CART_ITEMS";
export const ADD_TO_CART = "ADD_TO_CART";
export const UPDATE_CART = "UPDATE_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CLEAR_CART = "CLEAR_CART";
export const INCREMENT = "INCREMENT";
export const DECREMENT = "DECREMENT";
export const GET_TOTAL = "GET_TOTAL";
