import React, { useEffect, useState } from "react";
import {
  filterBooksByCategory,
  searchBooks,
  getNewArrivals,
  getmostViewedBooks,
  getPopularBooks,
  getAllBooks,
} from "../../../../redux/actions/allBooks/allBooks";

import { useDispatch, useSelector } from "react-redux";
import SubCat from "./SubCat";
// import Tags from "./Tags";
import { getCategories } from "../../../../redux/actions/bookCategories/categoriesActions";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "../../../queryHelper/QueryHelper";

const MainCat = ({ books }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let query = useQuery();
  let queryString = query.get("");

  const [searchValue, setSearchValue] = useState("");
  const [sortValue, setSortValue] = useState("");

  const { categories } = useSelector((state) => state.categories);

  // Get Category by ID
  useEffect(() => {
    dispatch(filterBooksByCategory(queryString));
  }, [dispatch, queryString]);

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  useEffect(() => {
    navigate("/books");
    dispatch(getAllBooks(sortValue, 10));
  }, [sortValue]);

  const getNewArrival = (trueValue) => {
    dispatch(getNewArrivals(trueValue, sortValue));
  };

  const mostViewedBooks = (trueValue) => {
    dispatch(getmostViewedBooks(trueValue));
  };

  const getPopular = (trueValue) => {
    dispatch(getPopularBooks(trueValue));
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchValue !== "") {
      dispatch(searchBooks(searchValue));
      navigate(`/books?search_books=${searchValue}`);
    }

    setSearchValue("");
  };

  const handleSortChange = (e) => {
    setSortValue(e.target.value);
  };

  const sideNav = (id) => {
    navigate(`/books?category=${id}`);
  };

  let ParentCategories;
  let trueData = true;

  ParentCategories =
    categories &&
    categories.map((parentCategory, idx) =>
      parentCategory &&
      parentCategory.child &&
      parentCategory.child.length > 0 ? (
        <>
          <div className="dropdown" key={idx}>
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {parentCategory.name}
            </button>
            <ul
              className="dropdown-menu dropdown-submenu"
              aria-labelledby="dropdownMenuButton1"
            >
              <SubCat category={parentCategory} id={parentCategory.id} />
            </ul>
          </div>
        </>
      ) : (
        <>
          <li className="pl-2 bg-light" key={idx}>
            <Link
              to={`/books?category=${parentCategory.id}`}
              style={{ cursor: "pointer" }}
              type="button"
            >
              {parentCategory.name}
            </Link>
          </li>
        </>
      )
    );

  return (
    <div className="sidebar-category">
      <ul className="ategory-list mb--0 accordion" id="catlv0">
        <li className="pl-2 bg-light">
          <Link to="/books" style={{ cursor: "pointer" }} type="button">
            All Books
          </Link>
        </li>

        <li className="pl-2 bg-light">
          <Link
            to={`/books?new_arrivals=${trueData}`}
            style={{ cursor: "pointer" }}
            type="button"
            onClick={() => getNewArrival("true")}
          >
            New Arrival
          </Link>
        </li>
        <li className="pl-2 bg-light">
          <Link
            to={`/books?most_viewed=${trueData}`}
            type="button"
            style={{ cursor: "pointer" }}
            onClick={() => mostViewedBooks("true")}
          >
            Most Viewed
          </Link>
        </li>
        <li className="pl-2 bg-light">
          <Link
            to={`/books?popular_books=${trueData}`}
            type="button"
            style={{ cursor: "pointer" }}
            onClick={() => getPopular("true")}
          >
            Popular Books
          </Link>
        </li>

        <div style={{ fontWeight: "bold" }}>Categories</div>
        {ParentCategories}
      </ul>

      <select
        className="form-select my-3"
        aria-label="Sort By"
        value={sortValue}
        onChange={handleSortChange}
      >
        <option value="">Sort By</option>
        <option value="price:desc">Price: High to Low</option>
        <option value="price:asc">Price: Low to High</option>
        <option value="average_rating:desc">Rating: High to Low</option>
        <option value="average_rating:asc">Rating: Low to High</option>
      </select>

      <div className="input-group flex-nowrap">
        <input
          type="text"
          id="searchValue"
          className="form-control"
          name="searchValue"
          value={searchValue}
          placeholder="Search"
          aria-label="Search"
          aria-describedby="addon-wrapping"
          onChange={(e) => handleChange(e)}
        />

        <span className="input-group-text" id="addon-wrapping">
          <button
            type="submit"
            className="fa fa-search"
            style={{ height: "100%", width: "100%", border: "none" }}
            onClick={handleSearch}
          ></button>
        </span>
      </div>

      {/* <aside className="wedget__categories poroduct--tag">
        <h3 className="wedget__title">Product Tags</h3>
        <Tags />
      </aside>{" "} */}
    </div>
  );
};

export default MainCat;
