import { ADD_REVIEW, GET_REVIEWS } from "../actions/reviews/types";

const initialState = {
  reviews: [],
  review: {},
  error: {},
  loading: true,
};

export const reviewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REVIEWS:
      return {
        ...state,
        reviews: action.payload,
        loading: false,
      };

    case ADD_REVIEW:
      return {
        ...state,
        review: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
