import React, { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../../redux/actions/cart/cartAction";
import { addUserLibrary } from "../../../redux/actions/userLibrary/userLibraryAction";
import { loadUser } from "../../../redux/actions/auth/authActions";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import BookInfo from "./BookInfo";
import Image from "../../Image/Image";

const BookView = ({ book, imgUrl }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const [qty, setQty] = useState(1);

  const { user, isAuthenticated } = useSelector((state) => state.user);

  const [cart, setCart] = useState({
    user: user ? user.id : "",
    status: "onCart",
    cart_items: [
      {
        book: id,
        item: 1,
      },
    ],
  });

  const [library, setLibrary] = useState({
    user: "",
    books: [],
  });

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  const increaseQty = () => {
    const { cart_items } = cart;
    cart_items[0] = { ...cart_items[0], item: cart_items[0].item + 1 };
    setCart({ cart_items });
  };

  const decreaseQty = () => {
    const { cart_items } = cart;
    if (1 >= cart_items[0].item) return;
    cart_items[0] = { ...cart_items[0], item: cart_items[0].item - 1 };
    // const q = qty - 1;
    setCart({ cart_items });
  };

  useEffect(() => {
    favBook();
  }, [library]);

  const addToLibrary = (e, userId, bookId) => {
    e.preventDefault();
    setLibrary({
      ...library,
      user: userId,
      books: [...library.books, bookId],
    });

    favBook();
  };

  const favBook = () => {
    if (library.user !== "") {
      dispatch(addUserLibrary(library));
    }
  };

  let load;
  const handleAddToCart = (e, cart) => {
    isAuthenticated === true
      ? dispatch(addToCart(cart, user.id))
      : toast.warn("Your are not logged In..!!");
    setTimeout(() => {
      navigate("/sign-in", { state: { from: location }, replace: true });
    }, 1000);
  };

  return (
    <div>
      <ToastContainer />
      {/* <!--== Start Product Single Area Wrapper ==--> */}
      <section className="product-area product-single-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="product-single-item">
                <div className="row">
                  <div className="col-md-4">
                    {/* <!--== Start Product Thumbnail Area ==--> */}
                    <div className="product-single-thumb product-single-thumb-normal">
                      <div className="swiper-container single-product-thumb single-product-thumb-slider">
                        <div className="swiper-wrapper">
                          <div className="swiper-slide">
                            <a
                              className="lightbox-image"
                              data-fancybox="gallery"
                              href=""
                            >
                              <Image
                                imageUrl={book?.thumbnail?.formats?.small?.url}
                                alt="Image-HasTech"
                                className="img-thumbnail"
                                style={"width=200px, height=300px"}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!--== End Product Thumbnail Area ==--> */}
                  </div>
                  <div className="col-md-6">
                    {/* <!--== Start Product Info Area ==--> */}
                    <div className="product-single-info">
                      <h3 className="main-title">{book.title}</h3>
                      <div className="prices">
                        <span className="price">NRs.{book.price}</span>
                      </div>
                      <div className="rating-box-wrap">
                        <div className="rating-box"></div>
                      </div>
                      <p>{book.description}</p>
                      <div className="product-quick-action">
                        <div className="qty-wrap">
                          <div className="pro-qty">
                            <div
                              type="button"
                              className="btn-danger dec qty-btn"
                              onClick={(e) => decreaseQty(e, book.id)}
                            >
                              -
                            </div>
                            {!cart.cart_items || cart.cart_items === undefined
                              ? ""
                              : cart.cart_items.map((cartItem, idx) => (
                                  <input
                                    key={idx}
                                    id="qty"
                                    className="input-text qty"
                                    name="qty"
                                    value={cartItem.item}
                                    type="number"
                                    readOnly
                                    disabled
                                  />
                                ))}
                            <div
                              type="button"
                              className="btn-success inc qty-btn"
                              onClick={(e) => increaseQty(e, book.id)}
                            >
                              +
                            </div>
                          </div>
                        </div>

                        <Link
                          to={`/book/${book.id}`}
                          className="btn-theme"
                          type="button"
                          title="Add to Cart"
                          onClick={(e) => handleAddToCart(e, cart)}
                        >
                          Add to Cart
                        </Link>
                      </div>

                      <div className="product-wishlist-compare">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={(e) => addToLibrary(e, user.id, book.id)}
                        >
                          <i className="pe-7s-like"></i>Add to Library
                        </a>
                      </div>
                      <div className="product-info-footer">
                        <h6 className="code">
                          <span>Code :</span>Ch-256xl
                        </h6>
                        <div className="social-icons">
                          <span>Share</span>
                          <a href="#/">
                            <i className="fa fa-facebook"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <!--== End Product Info Area ==--> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <BookInfo book={book} />
        </div>
      </section>
      {/* <!--== End Product Single Area Wrapper ==--> */}
    </div>
  );
};

export default BookView;
