import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import axios from "axios";
import { BASE_URL } from "../../constants/Constants";
import { toast } from "react-toastify";

const RegisterForm = () => {
  const [registerUser, setRegisterUser] = useState({
    username: "",
    email: "",
    password: "",
    address: "",
    phone: "",
    dob: "",
    fullName: "",
    gender: "",
  });

  const handleChange = (e) => {
    setRegisterUser({
      ...registerUser,
      [e.target.id]: e.target.value,
    });
  };

  const history = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(BASE_URL + "/auth/local/register", {
        ...registerUser,
        isDistributor: false,
        blocked: false,
      })
      .then(() => {
        toast.success("User Created");
        e.target.reset();
        history("/sign-in");
      })
      .catch((err) => {
        toast.error("Error");
      });
  };

  return (
    <main className="main-content">
      <div
        className="page-header-area parallax"
        style={{
          backgroundImage: `url(/assets/images/banner.jpg)`,
          backgroundBlendMode: "multiply",
          backgroundColor: "grey",
        }}
      >
        <div className="container pt--0 pb--0">
          <div className="row">
            <div className="col-12">
              <div className="page-header-content">
                <h2
                  className="title"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                  style={{ color: "#fff", textShadow: "1px 1px 2px red" }}
                >
                  Register
                </h2>
                <nav
                  className="breadcrumb-area"
                  data-aos="fade-down"
                  data-aos-duration="1200"
                >
                  <ul
                    className="breadcrumb"
                    style={{
                      color: "#fff",
                      textShadow: "1px 1px 2px black",
                    }}
                  >
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-sep">/</li>
                    <li>Register</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="account-area">
        <div className="container">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-6">
                <div className="login-form-content">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="username">
                          Username
                          <span className="required">*</span>
                        </label>
                        <input
                          id="username"
                          name="username"
                          className="form-control"
                          value={registerUser.username}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="fullName">
                          Full Name
                          <span className="required">*</span>
                        </label>
                        <input
                          id="fullName"
                          name="fullName"
                          className="form-control"
                          value={registerUser.fullName}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="email">
                          Email
                          <span className="required">*</span>
                        </label>
                        <input
                          id="email"
                          name="email"
                          className="form-control"
                          type="email"
                          value={registerUser.email}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="password">
                          Password <span className="required">*</span>
                        </label>
                        <input
                          id="password"
                          name="password"
                          className="form-control"
                          type="password"
                          value={registerUser.password}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="login-form-content">
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="dob">
                        DOB
                        <span className="required">*</span>
                      </label>
                      <input
                        id="dob"
                        name="dob"
                        className="form-control"
                        type="date"
                        value={registerUser.dob}
                        onChange={(e) =>
                          setRegisterUser({
                            ...registerUser,
                            dob: moment(e.target.valueAsDate).format(
                              "YYYY-MM-DD"
                            ),
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="address">
                        Address
                        <span className="required">*</span>
                      </label>
                      <input
                        id="address"
                        name="address"
                        className="form-control"
                        value={registerUser.address}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="phone">
                        Phone
                        <span className="required">*</span>
                      </label>
                      <input
                        id="phone"
                        name="phone"
                        className="form-control"
                        type="number"
                        value={registerUser.phone}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="gender">
                        Gender
                        <span className="required">*</span>
                      </label>
                      <select
                        name="gender"
                        className="form-select form-control"
                        aria-label="Default select example"
                        value={registerUser.gender}
                        onChange={(e) =>
                          setRegisterUser({
                            ...registerUser,
                            gender: e.target.value,
                          })
                        }
                      >
                        <option selected>Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Others</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="login-form-content">
                <div className="col-12">
                  <div className="form-group">
                    <button type="submit" className="btn-login">
                      Register
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
};

export default RegisterForm;
