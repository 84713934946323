import React from "react";
import GPlay from "../../../assets/google-play.svg";

const Footer = () => {
  return (
    <footer
      id="wn__footer"
      className="footer-area"
      style={{ backgroundColor: "#eee" }}
    >
      <div className="footer-static-top" style={{ backgroundColor: "#eee" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="footer__widget footer__menu">
                <div className="ft__logo">
                  <a href="/">
                    <img
                      src="/assets/images/logo/readmore-logo.png"
                      alt="logo"
                    />
                  </a>
                  <p>
                    “There is no friend as loyal as a book.”—Ernest Hemingway
                  </p>
                </div>
                <div className="footer__content">
                  <ul className="social__net social__net--2 d-flex justify-content-center">
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-google"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.app.read_more"
                        target="_blank"
                      >
                        <img src={GPlay} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright__wrapper">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="copyright">
                  <div className="copy__right__inner text-left">
                    <p>
                      Copyright <i className="fa fa-copyright"></i>{" "}
                      <a href="/">Readmore.</a> All Rights Reserved
                    </p>
                  </div>
                </div>
              </div>
              {/* Terms and condition */}
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="copyright">
                  <div className="copy__right__inner text-left">
                    <p>
                      <a href="/our-policy">Privacy Policy</a>,{" "}
                      <a href="/terms-conditions">Terms & Conditions</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
