import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getContact } from "../../../redux/actions/contact/contactActions";

const SideMenu = ({ user, isAuthenticated }) => {
  const dispatch = useDispatch();
  const { contact, loading } = useSelector((state) => state.contact);

  useEffect(() => {
    dispatch(getContact());
  }, [dispatch]);

  let auth =
    isAuthenticated === true ? (
      <li className="account">
        <a href="/my-dashboard">
          <i className="fa fa-user"></i>
          {user.username}
        </a>
      </li>
    ) : (
      <li className="account">
        <a href="/sign-in">
          <i className="fa fa-user"></i> Account
        </a>
      </li>
    );

  return (
    <div
      className="off-canvas-wrapper offcanvas offcanvas-start"
      tabIndex="-1"
      id="AsideOffcanvasMenu"
      aria-labelledby="offcanvasExampleLabel"
    >
      <div className="offcanvas-header">
        <h1 id="offcanvasExampleLabel"></h1>
        <button
          className="btn-menu-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          menu <i className="fa fa-chevron-left"></i>
        </button>
      </div>
      <div className="offcanvas-body">
        <div className="info-items">
          <ul>
            <li className="number">
              <a href={`tel:${contact.phone}`}>
                <i className="fa fa-phone"></i>
                {!contact.phone ? "" : contact.phone}
              </a>
            </li>
            <li className="email">
              <a href="mailto://demo@example.com">
                <i className="fa fa-envelope"></i>
                {!contact.email ? "" : contact.email}
              </a>
            </li>
            {auth}
          </ul>
        </div>
        {/* <!-- Mobile Menu Start --> */}
        <div className="mobile-menu-items">
          <ul className="nav-menu">
            <li>
              <a href="/">Home</a>
            </li>

            <li>
              <a href="/books">Books</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
          </ul>
        </div>
        {/* <!-- Mobile Menu End --> */}
      </div>
    </div>
  );
};

export default SideMenu;
