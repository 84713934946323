import axios from "axios";
import {
  FAIL_TEACHER_INFO,
  LOAD_TEACHER_INFO,
  REQUEST_TEACHER_INFO,
  UPDATE_TEACHER_INFO,
} from "./types";
import { linkUrl } from "../linkUrl";
import { toast } from "react-toastify";

export const getTeacherInfo = (teacherId) => (dispatch) => {
  dispatch({ type: REQUEST_TEACHER_INFO });
  axios
    .get(`${linkUrl}/teachers?user=${teacherId}`)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: LOAD_TEACHER_INFO,
          payload: result.data[0],
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: FAIL_TEACHER_INFO,
        payload: err.response,
      });
    });
};

export const updateTeacherInfo = (teacherId, teacherData) => (dispatch) => {
  axios
    .put(`${linkUrl}/teachers/${teacherId}`, teacherData)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: UPDATE_TEACHER_INFO,
          payload: result.data,
        });
        toast.success("Updated Successfully");
      }
    })
    .catch((err) => {
      toast.error("Oops! Something Went Wrong..!!");
    });
};
