import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../loaderComponent/Loader";
import { linkUrl } from "../../redux/actions/linkUrl";
import {
  IncrementToCart,
  decrementFromCart,
  removeFromCart,
  updateCart,
  getCartTotal,
  getItemsFromCart,
} from "../../redux/actions/cart/cartAction";
import { useNavigate } from "react-router-dom";

const Cart = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { arrCartItems, totalItem, totalAmount, loading } = useSelector(
    (state) => state.cart
  );

  const [cartUpdate, setCartupdate] = useState({
    user: "",
    status: "onCart",

    cart_items: [],
  });

  console.log("Update Cart", cartUpdate);

  // Get Cart Items from Server
  useEffect(() => {
    if (user) {
      dispatch(getItemsFromCart(user.id));
    }
  }, [dispatch, user]);

  // Set Update Cart
  useEffect(() => {
    if (arrCartItems && arrCartItems.cart_items !== undefined) {
      let result = arrCartItems.cart_items.map((c) => c);

      setCartupdate({
        user: arrCartItems.user,
        status: arrCartItems.status,
        cart_items: result,
      });
    }
  }, [
    !arrCartItems || arrCartItems.cart_items === undefined
      ? ""
      : arrCartItems.cart_items,
  ]);

  const increaseQty = (e, idx) => {
    const { cart_items } = cartUpdate;
    cart_items[idx] = { ...cart_items[idx], item: cart_items[idx].item + 1 };
    setCartupdate({ cart_items });
    updateCartInfo(cartUpdate);
  };

  const decreaseQty = (e, idx) => {
    const { cart_items } = cartUpdate;
    if (1 >= cart_items[idx].item) return;
    cart_items[idx] = { ...cart_items[idx], item: cart_items[idx].item - 1 };
    setCartupdate({ cart_items });
    updateCartInfo(cartUpdate);
  };

  const removeItem = (e, cartItemId) => {
    e.preventDefault();
    if (user) {
      dispatch(removeFromCart(cartItemId, user.id));
    }
  };

  // update cart
  const updateCartInfo = (update_cart) => {
    dispatch(updateCart(update_cart));
  };

  let cartAllData;
  if (cartUpdate !== undefined && cartUpdate.cart_items.length > 0) {
    cartAllData = cartUpdate.cart_items.map((data, idx) =>
      data.book === null ? (
        ""
      ) : (
        <tr className="cart-product-item" key={idx}>
          <td className="product-remove">
            <a
              className="text-danger"
              style={{ cursor: "pointer" }}
              type="button"
              onClick={(e) => removeItem(e, data.id)}
            >
              <i className="fa fa-trash-o"></i>
            </a>
          </td>
          <td className="product-thumb">
            {data &&
            data.book &&
            data.book.thumbnail &&
            data.book.thumbnail.formats &&
            data.book.thumbnail.formats.thumbnail &&
            data.book.thumbnail.formats.thumbnail.url ? (
              <a>
                <img
                  src={`${linkUrl}${data.book.thumbnail.formats.thumbnail.url}`}
                  width="90"
                  height="110"
                  alt={data.book.title}
                />
              </a>
            ) : (
              ""
            )}
          </td>
          <td className="product-name">
            <h4 className="title">
              <a>{data.book.title}</a>
            </h4>
          </td>
          <td className="product-price">
            <span className="price">NRs.{data.book.price}</span>
          </td>
          <td className="product-quantity">
            <div className="pro-qty">
              <div className="dec qty-btn" onClick={(e) => decreaseQty(e, idx)}>
                -
              </div>
              <input
                id="qty"
                type="text"
                className="quantity"
                title="Quantity"
                value={data.item}
                readOnly
                disabled
              />
              <div className="inc qty-btn" onClick={(e) => increaseQty(e, idx)}>
                +
              </div>
            </div>
          </td>
          <td className="product-subtotal">
            <span className="price">NRs.{data.book.price * data.item}</span>
          </td>
        </tr>
      )
    );
  }

  return (
    <>
      <main className="main-content">
        <div
          className="page-header-area parallax"
          style={{
            backgroundImage: `url(/assets/images/banner.jpg)`,
            backgroundBlendMode: "multiply",
            backgroundColor: "grey",
          }}
        >
          <div className="container pt--0 pb--0">
            <div className="row">
              <div className="col-12">
                <div className="page-header-content">
                  <h2
                    className="title"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                  >
                    <span
                      style={{ color: "#fff", textShadow: "1px 1px 2px red" }}
                    >
                      Shopping Cart
                    </span>
                  </h2>
                  <nav
                    className="breadcrumb-area"
                    data-aos="fade-down"
                    data-aos-duration="1200"
                  >
                    <ul
                      className="breadcrumb"
                      style={{
                        color: "#fff",
                        textShadow: "1px 1px 2px black",
                      }}
                    >
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li className="breadcrumb-sep">/</li>
                      <li>Shopping Cart</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <section className="shopping-cart-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="shopping-cart-form table-responsive">
                <form action="#" method="post">
                  <table className="table text-center">
                    <thead>
                      <tr>
                        <th className="product-remove">&nbsp;</th>
                        <th className="product-thumb">&nbsp;</th>
                        <th className="product-name">Product</th>
                        <th className="product-price">Price</th>
                        <th className="product-quantity">Quantity</th>
                        <th className="product-subtotal">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartAllData}

                      <tr className="actions">
                        <td className="border-0" colSpan="6">
                          <button
                            type="submit"
                            onClick={() => navigate("/check-out")}
                            className="btn-theme btn-flat"
                          >
                            Proceed to checkout
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cart;
