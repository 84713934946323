import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { getBanners } from "../../../../redux/actions/bannerSlider/bannersAction";
import { useDispatch, useSelector } from "react-redux";
import { linkUrl } from "../../../../redux/actions/linkUrl";

const Banner = () => {
  const dispatch = useDispatch();
  const { banners, loading } = useSelector((state) => state.banners);

  useEffect(() => {
    dispatch(getBanners());
  }, [dispatch]);

  const bannerData =
    banners &&
    banners.map((banner, idx) => (
      <div className="swiper-slide" key={idx}>
        <div
          className="slider-content-area slider-content-area-two"
          style={{
            backgroundImage: `url(${linkUrl}${banner?.images[0]?.url})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></div>
      </div>
    ));

  // Carousel Settings
  const settings = {
    infinite: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 3000,
    autoplaySpeed: 3000,
    autoplay: true,
    adaptiveHeight: true,
  };
  return (
    <section className="home-slider-area container">
      <div className="home-slider-container default-slider-container">
        <div className="home-slider-wrapper slider-default">
          <Slider
            {...settings}
            style={{ backgroundColor: "rgb(238, 238, 238)" }}
          >
            {bannerData}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Banner;
