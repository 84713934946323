import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../../redux/actions/auth/authActions";
import axios from "axios";
import { BASE_URL } from "../../constants/Constants";
import { toast } from "react-toastify";

const TeacherRequestForm = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const history = useNavigate();

  const [registerUser, setRegisterUser] = useState({
    schoolName: "",
    schoolAddress: "",
    schoolPhone: "",
    principalName: "",
    principalContact: "",
    schoolType: "",
    teachingClasses: "",
    teachingSubjects: "",
  });

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(BASE_URL + "/teachers", registerUser)
      .then(() => history(0))
      .catch(() => toast.error("Error"));
  };

  const handleChange = (e) => {
    setRegisterUser({
      ...registerUser,
      user: user.id,
      [e.target.id]: e.target.value,
    });
  };

  return (
    <>
      <button
        type="button"
        className="btn text-decoration-underline"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Request Teacher Access
      </button>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Request Form
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <main className="main-content">
                <form style={{ marginTop: "1em" }} onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-6">
                      <div className="login-form-content">
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <label htmlFor="schoolName">
                                School Name
                                <span className="required">*</span>
                              </label>
                              <input
                                id="schoolName"
                                name="schoolName"
                                className="form-control"
                                value={registerUser.schoolName}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="form-group">
                              <label htmlFor="schoolAddress">
                                School Address
                                <span className="required">*</span>
                              </label>
                              <input
                                id="schoolAddress"
                                name="schoolAddress"
                                className="form-control"
                                value={registerUser.schoolAddress}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="form-group">
                              <label htmlFor="schoolPhone">
                                School Phone
                                <span className="required">*</span>
                              </label>
                              <input
                                id="schoolPhone"
                                name="schoolPhone"
                                className="form-control"
                                type="number"
                                value={registerUser.schoolPhone}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="form-group">
                              <label htmlFor="principalName">
                                Principal Name{" "}
                                <span className="required">*</span>
                              </label>
                              <input
                                id="principalName"
                                name="principalName"
                                className="form-control"
                                value={registerUser.principalName}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="login-form-content">
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="principalContact">
                              Principal Contact
                              <span className="required">*</span>
                            </label>
                            <input
                              id="principalContact"
                              name="principalContact"
                              className="form-control"
                              type="number"
                              value={registerUser.principalContact}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="schoolType">
                              School Type
                              <span className="required">*</span>
                            </label>
                            <input
                              id="schoolType"
                              name="schoolType"
                              className="form-control"
                              value={registerUser.schoolType}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="teachingClasses">
                              Teaching Classes
                              <span className="required">*</span>
                            </label>
                            <input
                              id="teachingClasses"
                              name="teachingClasses"
                              className="form-control"
                              value={registerUser.teachingClasses}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="teachingSubjects">
                              Teaching Subjects
                              <span className="required">*</span>
                            </label>
                            <input
                              id="teachingSubjects"
                              name="teachingSubjects"
                              className="form-control"
                              value={registerUser.teachingSubjects}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="login-form-content">
                      <div className="col-12">
                        <div className="form-group">
                          <button type="submit" className="btn-login">
                            Register
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherRequestForm;
