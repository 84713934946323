import axios from "axios";
import {
  GET_CATEGORIES,
  GET_CATEGORY,
  GET_SUB_CATEGORY,
  GET_CATLV,
  GET_ERRORS,
  CLEAR_ERRORS,
  GET_CATLV3,
} from "./types";
import { linkUrl } from "../linkUrl";

export const getCategories = () => async (dispatch) => {
  axios
    .get(`${linkUrl}/categories?level=level0`)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: GET_CATEGORIES,
          payload: result.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

export const getCatlv = (level2) => async (dispatch) => {
  axios
    .get(`${linkUrl}/categories?level=${level2}`)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: GET_CATLV,
          payload: result.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

export const getCatlv3 = (level3) => async (dispatch) => {
  axios
    .get(`${linkUrl}/categories?level=${level3}`)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: GET_CATLV3,
          payload: result.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

export const getCategoryById = (id) => async (dispatch) => {
  axios
    .get(`${linkUrl}/categories/${id}`)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: GET_CATEGORY,
          payload: result.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

export const getSubCategoryById = (id) => async (dispatch) => {
  axios
    .get(`${linkUrl}/categories/${id}`)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: GET_SUB_CATEGORY,
          payload: result.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

// clear Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
