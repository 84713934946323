import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  getTeacherInfo,
  updateTeacherInfo,
} from "../../../../redux/actions/teacher/teacherActions";
import Loader from "../../../loaderComponent/Loader";

const AcademicInfo = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { teacher, loading } = useSelector((state) => state.teacher);

  const [schoolInfo, setSchoolInfo] = useState({
    schoolName: "",
    schoolAddress: "",
    schoolPhone: "",
    schoolType: "",
    principalName: "",
    principalContact: "",
    teachingClasses: "",
    teachingSubjects: "",
  });

  useEffect(() => {
    if (user !== null) {
      dispatch(getTeacherInfo(user.id));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (teacher && teacher !== undefined) {
      setSchoolInfo({
        ...schoolInfo,
        schoolName: teacher.schoolName,
        schoolAddress: teacher.schoolAddress,
        schoolPhone: teacher.schoolPhone,
        schoolType: teacher.schoolType,
        principalName: teacher.principalName,
        principalContact: teacher.principalContact,
        teachingClasses: teacher.teachingClasses,
        teachingSubjects: teacher.teachingSubjects,
      });
    }
  }, [teacher]);

  const handleChange = (e) => {
    setSchoolInfo({
      ...schoolInfo,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (user) {
      dispatch(updateTeacherInfo(user.id, schoolInfo));
    }
  };

  let teacherForm =
    !teacher || teacher === undefined ? (
      ""
    ) : (
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <div className="single-input-item">
          <label htmlFor="school name" className="required">
            School Name
          </label>
          <input
            type="text"
            id="schoolName"
            name="schoolName"
            placeholder="School Name"
            value={schoolInfo.schoolName}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="single-input-item">
              <label htmlFor="principal" className="required">
                Principal Name
              </label>
              <input
                type="text"
                name="principalName"
                id="principalName"
                value={schoolInfo.principalName}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="single-input-item">
              <label htmlFor="inputState" className="required">
                Contact
              </label>
              <input
                type="number"
                className="form-control"
                name="principalContact"
                id="principalContact"
                value={schoolInfo.principalContact || ""}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
        </div>
        <div className="single-input-item">
          <label htmlFor="school address" className="required">
            School Address
          </label>
          <input
            type="text"
            id="schoolAddress"
            name="schoolAddress"
            placeholder="School Address"
            value={schoolInfo.schoolAddress}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="single-input-item">
              <label htmlFor="schoolPhone" className="required">
                School Number
              </label>
              <input
                type="number"
                name="schoolPhone"
                id="schoolPhone"
                value={schoolInfo.schoolPhone}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="single-input-item">
              <label htmlFor="schoolType" className="required">
                School Type
              </label>
              <input
                type="text"
                name="schoolType"
                id="schoolType"
                value={schoolInfo.schoolType || ""}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-6">
            <div className="single-input-item">
              <label htmlFor="teachingClasses" className="required">
                Teaching Classes
              </label>
              <input
                type="text"
                name="teachingClasses"
                id="teachingClasses"
                value={schoolInfo.teachingClasses}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="single-input-item">
              <label htmlFor="teachingSubjects" className="required">
                Teaching Subjects
              </label>
              <input
                type="text"
                name="teachingSubjects"
                id="teachingSubjects"
                value={schoolInfo.teachingSubjects || ""}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
        </div>

        <button type="submit" className="btn btn-sm btn-primary">
          Update Academic Info
        </button>
      </form>
    );
  return <div className="content"> {loading ? <Loader /> : teacherForm}</div>;
};

export default AcademicInfo;
