import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../loaderComponent/Loader";
import { getTeacherInfo } from "../../../../redux/actions/teacher/teacherActions";
import axios from "axios";
import ReadBook from "../../../ReadBook/ReadBook";
// import {
//   getUserLibrary,
//   deleteUserLibrary,
// } from "../../../redux/actions/userLibrary/userLibraryAction";

const TeacherBooks = ({ user }) => {
  const dispatch = useDispatch();
  const { libraries } = useSelector((state) => state.userLibrary);

  const { teacher, loading, imgUrl } = useSelector((state) => state.teacher);

  useEffect(() => {
    if (user) {
      dispatch(getTeacherInfo(user.id));
    }
  }, [dispatch, user]);

  const booksMapped = teacher?.assignedBooks?.map((book) => (
    <ReadBook book={book} />
  ));

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="wishlist-content">
                <div className="wishlist-table wnro__table table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Book</th>
                        <th>
                          <span>Book Name</span>
                        </th>

                        <th className="product-add-to-cart"></th>
                      </tr>
                    </thead>
                    <tbody>{booksMapped}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TeacherBooks;
