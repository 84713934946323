import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../../../redux/actions/auth/authActions";
import { getTeacherInfo } from "../../../redux/actions/teacher/teacherActions";
import TeacherRequestForm from "../../TeacherRequestForm/TeacherRequestForm";
import DashmainContent from "./DashmainContent";
import DashSidebar from "./DashSidebar";

const Dashboard = () => {
  return (
    <main className="main-content">
      <section className="my-account-area">
        <div className="container pt--0 pb--0">
          <div className="row">
            <div className="col-lg-12">
              <div className="myaccount-page-wrapper">
                <div className="row">
                  <div className="col-lg-3 col-md-4">
                    <DashSidebar />
                  </div>
                  <div className="col-lg-9 col-md-8">
                    <DashmainContent />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Dashboard;
