import axios from "axios";
import {
  FAIL_SHIPPING_INFO,
  GET_DELIVERY_ZONES,
  GET_SHIPPING_INFO,
  REQUEST_DELIVERY_ZONES,
  REQUEST_SHIPPING_INFO,
  GET_ERRORS,
  POST_SHIPPING_INFO,
  REQUEST_DELIVERY_RATES,
  GET_DELIVERY_RATE,
  CALC_DELIVERY_CHARGE,
} from "./types";

import { linkUrl } from "../linkUrl";
import { toast } from "react-toastify";

export const fetchShippingAddress = (userId) => async (dispatch) => {
  dispatch({
    type: REQUEST_SHIPPING_INFO,
  });
  await axios
    .get(`${linkUrl}/shipping-infos?user=${userId}`)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: GET_SHIPPING_INFO,
          payload: result.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: FAIL_SHIPPING_INFO,
        type: err.response,
      });
    });
};

export const postShippingAddress = (shippingAddress) => async (dispatch) => {
  dispatch({
    type: REQUEST_SHIPPING_INFO,
  });
  await axios
    .post(`${linkUrl}/shipping-infos`, shippingAddress)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: POST_SHIPPING_INFO,
          payload: result.data,
        });
        toast.success("New Shipping Address Has Added Successfully");
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      toast.error("Oops! Somethinf Went Wrong..");
    });
};

export const getDeliveryZones = () => async (dispatch) => {
  dispatch({
    type: REQUEST_DELIVERY_ZONES,
  });
  await axios
    .get(`${linkUrl}/delivery-zones`)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: GET_DELIVERY_ZONES,
          payload: result.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      });
    });
};

// GET DELIVERY RATES
export const getDeliveryRates = () => async (dispatch) => {
  dispatch({
    type: REQUEST_DELIVERY_RATES,
  });
  await axios
    .get(`${linkUrl}/delivery-rates`)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: GET_DELIVERY_RATE,
          payload: result.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      });
    });
};

// Calculate Delivery Charge
export const calcDeliveryCharge =
  (rates, delivery_zone, totalWeight) => (dispatch) => {
    let deliveryZone;
    let total_weight;
    let deliveryCharge;
    let matchZone;
    let max_weight;
    let max_rate;

    let weightDiff;
    let maxPriceTotal;

    if (delivery_zone === "" || delivery_zone === undefined) {
    } else {
      deliveryZone = delivery_zone.delivery_zone;
    }

    if (totalWeight === "" || totalWeight === undefined) {
    } else {
      total_weight = totalWeight;
    }

    if (!rates || rates.rate === undefined) {
    } else {
      matchZone = rates.rate.cod.zones[deliveryZone];
      // total_weight = 10;
      if (!matchZone || matchZone.rates === undefined) {
      } else {
        for (let i = 0; i < matchZone.rates.length; i++) {
          max_weight = Math.max(matchZone.rates[i].weight);
          max_rate = Math.max(matchZone.rates[i].rate);
          if (total_weight !== 0 && total_weight < matchZone.rates[i].weight) {
            deliveryCharge = matchZone.rates[i].rate;
          } else if (total_weight > max_weight) {
            weightDiff = total_weight - max_weight;
            deliveryCharge = max_rate + weightDiff * matchZone.additionalweight;
          }
        }
      }
    }

    dispatch({
      type: CALC_DELIVERY_CHARGE,
      payload: deliveryCharge,
    });
  };
