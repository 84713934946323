import {
  GET_CONTACT,
  REQUEST_CONTACT,
  GET_ERRORS,
  CLEAR_ERRORS,
} from "../actions/contact/types";
import { linkUrl } from "../actions/linkUrl";

const initialState = {
  contact: {},
  imgUrl: {},
  errors: {},
  loading: true,
};

export const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_CONTACT:
      return {
        ...state,
      };
    case GET_CONTACT:
      return {
        ...state,
        contact: action.payload,
        loading: false,
      };

    case GET_ERRORS:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
