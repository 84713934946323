import axios from "axios";
import {
  REVIEWS_REQUEST,
  ADD_REVIEW,
  DELETE_REVIEW,
  GET_ERRORS,
  CLEAR_ERRORS,
  GET_REVIEWS,
} from "./types";

import { linkUrl } from "../linkUrl";
import { toast } from "react-toastify";

export const addReview = (review) => (dispatch) => {
  dispatch({ type: REVIEWS_REQUEST });
  axios
    .post(`${linkUrl}/reviews`, review)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: ADD_REVIEW,
          payload: result.data,
        });
        toast.success("Your Reviews Has Successfully Updated");
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      });
      toast.error("Oops! Something Went Wrong..!");
    });
};

export const getReviews = (bookId) => (dispatch) => {
  axios(`${linkUrl}/reviews?book=${bookId}`)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: GET_REVIEWS,
          payload: result.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      });
    });
};
