import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../constants/Constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgotPassword = () => {
  const [formDetails, setFormDetails] = useState({
    email: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const history = useNavigate();

  const handleChange = (e) => {
    setFormDetails({
      [e.target.id]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    axios
      .post(BASE_URL + "/auth/forgot-password", {
        email: formDetails.email,
      })
      .then(() => {
        toast.success("Check your email for further instructions");
        setTimeout(() => {
          history("/");
        }, 2000);
      })
      .catch((err) => toast.error(err))
      .finally(() => setIsLoading(false));
  };

  return (
    <div>
      <ToastContainer autoClose={2000} />
      <main className="main-content">
        <div
          className="page-header-area parallax"
          style={{
            backgroundImage: `url(/assets/images/banner.jpg)`,
            backgroundBlendMode: "multiply",
            backgroundColor: "grey",
          }}
        >
          <div className="container pt--0 pb--0">
            <div className="row">
              <div className="col-12">
                <div className="page-header-content">
                  <h2
                    className="title"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                    style={{ color: "#fff", textShadow: "1px 1px 2px red" }}
                  >
                    Forgot Password
                  </h2>
                  <nav
                    className="breadcrumb-area"
                    data-aos="fade-down"
                    data-aos-duration="1200"
                  >
                    <ul
                      className="breadcrumb"
                      style={{
                        color: "#fff",
                        textShadow: "1px 1px 2px black",
                      }}
                    >
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-sep">/</li>
                      <li>Forgot Password</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="account-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="login-form-content">
                  <form onSubmit={onSubmit}>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="username">
                            Email address
                            <span className="required">*</span>
                          </label>
                          <input
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Email"
                            type="email"
                            value={formDetails.email}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <button
                            disabled={isLoading}
                            type="submit"
                            className="btn-login"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ForgotPassword;
