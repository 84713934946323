import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { updatePassword } from "../../../../redux/actions/auth/authActions";

const PasswordChange = ({ user }) => {
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState({
    user: user.id,
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const handleChange = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updatePassword(userInfo));
  };

  return (
    <div>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <div className="single-input-item">
          <label htmlFor="email" className="required">
            Email Addres
          </label>
          <input type="email" id="email" value={user.email} readOnly disabled />
        </div>
        <fieldset>
          <legend>Password change</legend>
          <div className="single-input-item">
            <label htmlFor="current-pwd" className="required">
              Current Password
            </label>
            <input
              type="password"
              id="old_password"
              placeholder="Password"
              name="old_password"
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="single-input-item">
                <label htmlFor="new_password" className="required">
                  New Password
                </label>
                <input
                  type="password"
                  id="new_password"
                  name="new_password"
                  placeholder="Password"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-input-item">
                <label htmlFor="confirm_password" className="required">
                  Confirm Password
                </label>
                <input
                  type="password"
                  id="confirm_password"
                  name="confirm_password"
                  placeholder="Confirm Password"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
          </div>
        </fieldset>
        <div className="single-input-item">
          <button className="check-btn sqr-btn">Save Changes</button>
        </div>
      </form>
    </div>
  );
};

export default PasswordChange;
