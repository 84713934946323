import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrder } from "../../../../redux/actions/order/orderAction";

const Orders = ({ user }) => {
  const dispatch = useDispatch();
  const { orders, loading } = useSelector((state) => state.myOrders);


  useEffect(() => {
    if (user) {
      dispatch(fetchOrder(user.id));
    }
  }, [dispatch, user]);

  let orderData;

  if (loading) {
    return loading;
  } else {
    orderData =
      orders &&
      orders.map((order, idx) => (
        <tr key={idx}>
          <th scope="row">{idx + 1}</th>
          <td>{order.shipping_info.address}</td>
          <td>{String(order.shipping_info.created_at).substring(0, 10)}</td>
          {/* <td>{orders.items.length <= 0 ? "" : orders.items.length}</td> */}
          <td>{order.sub_total}</td>
          <td>{order.status}</td>
        </tr>
      ));
  }
  return (
    <table className="table table-bordered">
      <thead className="thead-light">
        <tr>
          <th>Order</th>
          <th>Address</th>
          <th>Date</th>
          <th>Total</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>{orderData}</tbody>
    </table>
  );
};

export default Orders;
