import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { getCategories } from "../../../../redux/actions/bookCategories/categoriesActions";
import Image from "../../../Image/Image";

const Category = () => {
  const dispatch = useDispatch();
  const { categories, imgUrl, loading } = useSelector(
    (state) => state.categories
  );

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  let bookCategory;

  if (loading) {
    return "";
  } else {
    bookCategory =
      categories &&
      categories.map((category, idx) => (
        <div
          className={`col-sm-6 col-md-6 col-lg-4 col-xl-3 ${category.name}`}
          style={{
            order:
              category.name.toLowerCase() === "readmore"
                ? 1
                : category.name.toLowerCase() === "bookzone"
                ? 4
                : category.name.toLowerCase() === "im"
                ? 3
                : 2,
          }}
          key={idx}
        >
          {/* <!--== Start Product Category Item ==--> */}
          <div className=" d-flex justify-content-center align-items-center">
            <div className="product-category">
              <div className="inner-content">
                <Image
                  imageUrl={category?.icon?.formats?.small?.url}
                  alt="Image-HasTech"
                />
                <a
                  className="banner-link-overlay"
                  href={`/books?category=${category.id}`}
                ></a>
              </div>
            </div>
          </div>
          {/* <!--== End Product Category Item ==--> */}
        </div>
      ));
  }

  return (
    <section className="product-area product-category-area">
      <div className="container">
        <div className="row">{bookCategory}</div>
      </div>
    </section>
  );
};

export default Category;
