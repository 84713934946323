import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import {
  getUserLibrary,
  deleteUserLibrary,
} from "../../../../redux/actions/userLibrary/userLibraryAction";

const Library = ({ user }) => {
  const dispatch = useDispatch();
  const { libraries, loading, imgUrl } = useSelector(
    (state) => state.userLibrary
  );

  useEffect(() => {
    if (user) {
      dispatch(getUserLibrary(user.id));
    }
  }, [dispatch, user]);

  const deleteLibrary = (bookId) => {
    if (libId !== "" || libId !== undefined) {
      dispatch(deleteUserLibrary(libId, bookId));
    }
    dispatch(getUserLibrary(user.id));
  };

  let libId;
  let libraryBooks;
  if (libraries?.books) {
    <div></div>;
  } else {
    if (loading) {
      return loading;
    } else {
      libraries.map((libBooks) => {
        libId = libBooks.id;
        libraryBooks =
          libBooks === undefined || libBooks.books.length <= 0 ? (
            <h4 style={{ padding: "10px" }}>Library is Empty</h4>
          ) : (
            libBooks.books.map((book, idx) => (
              <tr key={idx}>
                <td className="product-remove">
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => deleteLibrary(book.id)}
                  >
                    X
                  </button>
                </td>
                <td className="product-thumbnail">
                  <a href="#">
                    <img
                      src={`${imgUrl}${book?.thumbnail?.formats?.thumbnail?.url}`}
                      alt=""
                      width="50px"
                    />
                  </a>
                </td>
                <td className="product-name">
                  <a href="#">{book.title}</a>
                </td>
                <td className="product-price">
                  <span className="amount">NRs.{book.price}</span>
                </td>

                <td className="product-add-to-cart">
                  <button href="#" className="btn btn-sm btn-primary">
                    Add to Cart
                  </button>
                </td>
              </tr>
            ))
          );
      });
    }
  }

  return (
    <>
      <ToastContainer />
      <form action="#" className="container">
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th></th>
              <th>Book</th>
              <th>
                <span>Product Name</span>
              </th>
              <th className="product-price">
                <span> Unit Price </span>
              </th>

              <th></th>
            </tr>
          </thead>
          <tbody>{libraryBooks}</tbody>
        </table>
      </form>
    </>
  );
};

export default Library;
