import axios from "axios";
import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  UPDATE_CART,
  CLEAR_CART,
  INCREMENT,
  DECREMENT,
  GET_TOTAL,
  GET_CART_ITEMS,
  FAIL_CART_ITEMS,
  REQUEST_CART_ITEMS,
} from "./types";
import { linkUrl } from "../linkUrl";
import { toast } from "react-toastify";

export const getItemsFromCart = (userId) => async (dispatch) => {
  dispatch({
    type: REQUEST_CART_ITEMS,
  });
  await axios
    .get(`${linkUrl}/carts?user=${userId}&status=onCart`)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: GET_CART_ITEMS,
          payload:
            result.data && result.data.length <= 0
              ? result.data
              : result.data[0],
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: FAIL_CART_ITEMS,
        payload: err.response,
      });
    });
};

export const addToCart = (cart, userId) => async (dispatch, getState) => {
  await axios
    .post(`${linkUrl}/carts`, cart)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: ADD_TO_CART,
          payload: result.data,
        });
        dispatch(getItemsFromCart(userId));
        toast.success("Item Added To Cart");
      }
    })
    .catch((err) => {
      dispatch({
        type: FAIL_CART_ITEMS,
        payload: err,
      });
      toast.error("Oops! Something Went Wrong...");
    });
  // const cartItems = getState().cart.cartItems.slice();
  // let alreadyExists = false;
  // cartItems.forEach((x) => {
  //   if (x.id === product.id) {
  //     alreadyExists = true;
  //     x.count++;
  //   }
  // });
  // if (!alreadyExists) {
  //   cartItems.push({ ...product, count });
  // }
  // dispatch({
  //   type: ADD_TO_CART,
  //   payload: { cartItems },
  // });
  // localStorage.setItem("cartItems", JSON.stringify(cartItems));
};

export const updateCart = (cart) => (dispatch, getState) => {
  axios
    .post(`${linkUrl}/carts`, cart)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: UPDATE_CART,
          payload: result.data,
        });
      }
    })
    .catch((err) => {});
};

// export const IncrementToCart = (product) => (dispatch, getState) => {
//   const cartItems = getState().cart.cartItems.slice();
//   cartItems.forEach((c) => {
//     if (c.id === product.id) {
//       c.count++;
//     }
//   });
//   dispatch({
//     type: INCREMENT,
//     payload: { cartItems },
//   });
//   localStorage.setItem("cartItems", JSON.stringify(cartItems));
// };

// export const decrementFromCart = (product) => (dispatch, getState) => {
//   const cartItems = getState().cart.cartItems.slice();
//   cartItems.forEach((c) => {
//     if (c.id === product.id) {
//       c.count--;
//     }
//   });
//   cartItems.filter((x) => x.count !== 0);
//   dispatch({
//     type: DECREMENT,
//     payload: { cartItems },
//   });
//   localStorage.setItem("cartItems", JSON.stringify(cartItems));
// };

export const removeFromCart = (cartItemId, useId) => (dispatch, getState) => {
  axios
    .delete(`${linkUrl}/cart-items/${cartItemId}`)
    .then((result) => {
      dispatch({
        type: REMOVE_FROM_CART,
        payload: result.data,
      });
      dispatch(getItemsFromCart(useId));
      toast.success("Item Removed From Cart");
    })
    .catch((err) => {
      dispatch({
        type: FAIL_CART_ITEMS,
        payload: err.response,
      });
    });
};

// export const removeFromCart = (product) => (dispatch, getState) => {
//   const cartItems = getState()
//     .cart.cartItems.slice()
//     .filter((x) => x.id !== product.id);
//   dispatch({
//     type: REMOVE_FROM_CART,
//     payload: { cartItems },
//   });
//   localStorage.setItem("cartItems", JSON.stringify(cartItems));
// };

export const clearTotal = () => (dispatch) => {
  dispatch({ type: "CLEAR_TOTAL" });
};

export const getCartTotal = (arrCartItems) => (dispatch, getState) => {
  let stateData;
  if (!arrCartItems || arrCartItems.cart_items === undefined) {
    return;
  } else {
    stateData = arrCartItems.cart_items.slice();
  }

  let { totalItems, totalAmount, totalWeight } = stateData.reduce(
    (acc, curr) => {
      let { item, book } = curr;
      let { price, weight } = book;

      let updatedTotalAmount = price * item;
      acc.totalAmount += updatedTotalAmount;
      acc.totalItems += item;

      let updateedTotalWeight = weight * item;
      acc.totalWeight += updateedTotalWeight;
      return acc;
    },
    { totalAmount: 0, totalItems: 0, totalWeight: 0 }
  );
  dispatch({
    type: GET_TOTAL,
    payload: { totalItems, totalAmount, totalWeight },
  });
};
