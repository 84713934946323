import {
  GET_POPULAR_BOOKS,
  FAIL_POPULAR_BOOKS,
  GET_ERRORS,
  CLEAR_ERRORS,
} from "../actions/popularBooks/types";
import { linkUrl } from "../actions/linkUrl";

const initialState = {
  popularBooks: [],
  imgUrl: {},
  errors: {},
  loading: true,
};

export const popularBooksReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_POPULAR_BOOKS:
      return {
        ...state,
        popularBooks: action.payload,
        imgUrl: linkUrl,
        loading: false,
      };

    case GET_ERRORS:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
