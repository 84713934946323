import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Loader from "../../loaderComponent/Loader";
import { getSingleBook } from "../../../redux/actions/allBooks/allBooks";
import { addToCart } from "../../../redux/actions/cart/cartAction";

// import ProductReview from "./ProductReview";
// import RelatedProduct from "./RelatedProduct";

// import SingleProduct from "./SingleProduct";
import BookView from "./BookView";
import { ToastContainer } from "react-toastify";
import BookReviews from "./BookReviews";
import RelatedProducts from "./RelatedProducts";

const SingleBook = ({ user }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading, book, imgUrl } = useSelector((state) => state.allBooks);

  useEffect(() => {
    dispatch(getSingleBook(id));
  }, [dispatch, id]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <main className="main-content">
          <ToastContainer autoClose={2000} />
          <div
            className="page-header-area"
            style={{
              backgroundImage: `url(/assets/images/banner.jpg)`,
              backgroundBlendMode: "multiply",
              backgroundColor: "grey",
            }}
          >
            <div className="container pt--0 pb--0">
              <div className="row">
                <div className="col-12">
                  <div className="page-header-content">
                    <h2
                      className="title"
                      data-aos="fade-down"
                      data-aos-duration="1000"
                    >
                      <span
                        style={{ color: "#fff", textShadow: "1px 1px 2px red" }}
                      >
                        Book Details
                      </span>
                    </h2>
                    <nav
                      className="breadcrumb-area"
                      data-aos="fade-down"
                      data-aos-duration="1200"
                    >
                      <ul
                        className="breadcrumb"
                        style={{
                          color: "#fff",
                          textShadow: "1px 1px 2px black",
                        }}
                      >
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li className="breadcrumb-sep">/</li>
                        <li>Book Details</li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--== End Page Header Area Wrapper ==--> */}

          <BookView book={book} imgUrl={imgUrl} />

          <RelatedProducts categoryID={book.category.id} />
        </main>
      )}
    </>
  );
};

export default SingleBook;
