import axios from "axios";
import {
  GET_ERRORS,
  CLEAR_ERRORS,
  GET_CONTACT,
  REQUEST_CONTACT,
} from "./types";
import { linkUrl } from "../linkUrl";

export const getContact = () => async (dispatch) => {
  dispatch({
    type: REQUEST_CONTACT,
  });
  axios
    .get(`${linkUrl}/contact-details`)
    .then((result) => {
      if (result.status === 200) {
        dispatch({
          type: GET_CONTACT,
          payload: result.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

// clear Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
